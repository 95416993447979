import React, { useState } from 'react';
import { v4 } from 'uuid';
import styles from '../../../styles/common/connections/pipeSettings.module.scss';

interface UnitEnumOption {
  value: string;
  label: string;
}

function PipeUnitParameter({
  title,
  initialUnit,
  options,
  style = undefined,
}: {
  title: { label: string; id: string };
  initialUnit: { value: string; unit: string };
  options: UnitEnumOption[];
  style?: React.CSSProperties;
}) {
  const [unitEnum, setUnitEnum] = useState(initialUnit);

  return (
    <div className={styles.parameter}>
      <p className={styles.paramText}>{title.label}</p>
      <div className={styles.inputWrapper}>
        <fieldset id={title.id}>
          <input
            type="number"
            id="value"
            className={styles.input}
            value={unitEnum.value ?? ''}
            onChange={(e) => {
              setUnitEnum({ ...unitEnum, value: e.currentTarget.value });
            }}
          />
          <select
            name="units"
            id="unit"
            className={styles.select}
            value={unitEnum.unit}
            style={style}
            onChange={(e) => {
              setUnitEnum({ ...unitEnum, unit: e.currentTarget.value });
            }}
          >
            {options.map((option) => (
              <option key={v4()} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </fieldset>
      </div>
    </div>
  );
}

PipeUnitParameter.defaultProps = {
  style: undefined,
};

export default PipeUnitParameter;
