import { v4 } from 'uuid';
import handleFermentationTankData, {
  handleValveData,
  handlePipeData,
} from '../api/handlers';
import {
  DataPacket,
  SimulationStorage,
  TimeSeriesTypes,
} from '../api/types/nodes';
import { SimulationStatus, StatusType } from '../api/types/simulationTypes';
import { WebSocketServerResponse } from '../api/types/websocketMessage';

export function handleSimulationStatus(
  webSocketData: WebSocketServerResponse<SimulationStatus>,
  addStatus: (status: SimulationStatus) => void,
  setSimulating: (as: boolean) => void
) {
  const { status, message, title, id } = webSocketData.data;
  switch (status) {
    case StatusType.success:
      addStatus({ status, message, title, id });
      setSimulating(false);
      break;
    case StatusType.error:
      addStatus({ status, message, title, id });
      setSimulating(false);
      break;
    case StatusType.warning:
      addStatus({ status, message, title, id });
      break;
    default:
      break;
  }
}

export function handleSimulationData(
  webSocketData: WebSocketServerResponse<DataPacket<any>>,
  addStatus: (status: SimulationStatus) => void
) {
  const { data } = webSocketData;

  const { type } = data;
  const { simID } = data;

  localStorage.setItem('simID', simID);

  // Grab the simulation data if it exists.
  const existingDataStr = localStorage.getItem('simulate');
  let initialState: SimulationStorage = {
    [simID]: {
      fermentationTanks: {},
      pipes: {},
      valves: {},
    },
  };

  if (existingDataStr) {
    const initialData = JSON.parse(existingDataStr) as
      | SimulationStorage
      | undefined;
    if (initialData) {
      const initialSimState = initialData[simID];
      if (initialSimState) {
        initialState = initialData;
      } else {
        initialState = { ...initialState, ...initialData };
      }
    }
  }

  try {
    switch (type) {
      case TimeSeriesTypes.FERMENTATIONTANK:
        handleFermentationTankData(data.data, initialState, simID);
        break;
      case TimeSeriesTypes.VALVE:
        handleValveData(data.data, initialState, simID);
        break;
      case TimeSeriesTypes.PIPE:
        handlePipeData(data.data, initialState, simID);
        break;
      default:
        break;
    }
  } catch {
    localStorage.removeItem('simulate');
    addStatus({
      id: v4(),
      message:
        'Whoops, you ran too many simulations, your data might be truncated.',
      title: 'Too many simulations',
      status: StatusType.warning,
    });
  }
}
