import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../../styles/editor/canvas/editableNode.module.scss';

function NodeBottom({ id, type }: { id: string; type: string }) {
  return (
    <section className={styles.bottomContainer}>
      <div className={styles.topBar}>
        <div className={styles.toggleSection}>
          <p className={`${styles.toggleText} ${styles.active}`}>Output</p>
          <p className={styles.toggleText}>Chat</p>
          <Link
            to={{
              pathname: `dashboard/equipment/${type}/${id}`,
            }}
          >
            <p className={styles.toggleText}>Graph</p>
          </Link>
        </div>
        <div className={styles.statusIcons} />
      </div>
      <div className={styles.results} />
    </section>
  );
}

export default NodeBottom;
