/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Node } from 'reactflow';
import styles from '../../../styles/common/connections/signalSettings.module.scss';
import useStore, { RFState } from '../../../context/editor/store/store';
import useSignalStore, {
  SignalState,
} from '../../../context/editor/store/signalStore';
import GroupedComparator, { ControllerCase } from './GroupedComparator';
import EQUIPMENTTYPE from '../../../types/equipmentTypes';
import {
  SignalValueType,
  Comparison,
  TriggerValueBool,
  TriggerValueDouble,
  TriggerValueString,
  TriggerValueUnit,
  ControlProperty,
  RawControlSignal,
  RawControlSource,
  RawControlTarget,
} from '../../../types/signalTypes';
import {
  selectControlProperties,
  selectRespondingProperties,
  selectedComparisonType,
} from './utils/retrieveStaticProperties';

function SignalSettings({
  id,
  clientX,
  clientY,
  sourceID,
  targetID,
}: {
  id: string;
  clientX: number;
  clientY: number;
  sourceID: string;
  targetID: string;
}) {
  const { nodes, edges, setEdges } = useStore((state: RFState) => ({
    nodes: state.nodes,
    edges: state.edges,
    setEdges: state.setEdges,
  }));
  const { setActiveSignalSettings } = useSignalStore((state: SignalState) => ({
    setActiveSignalSettings: state.setActiveSignalSettings,
  }));

  const [source, setSource] = useState<Node>();
  const [target, setTarget] = useState<Node>();

  // MARK: Control Properties

  const [controlProperties, setControlProperties] = useState(
    selectControlProperties(source?.type as EQUIPMENTTYPE)
  );

  const [controlPropValue, setControlPropValue] = useState<
    | TriggerValueBool
    | TriggerValueDouble
    | TriggerValueString
    | TriggerValueUnit
  >();

  const [selectedControlProperty, setSelectedControlProperty] =
    useState<ControlProperty>();

  const [controlPropComparsion, setControlPropComparison] =
    useState<Comparison>();

  // MARK: Response Properties

  const [responseProperties, setResponseProperties] = useState(
    selectRespondingProperties(source?.type as EQUIPMENTTYPE)
  );

  const [responsePropValue, setResponsePropValue] = useState<
    | TriggerValueBool
    | TriggerValueDouble
    | TriggerValueString
    | TriggerValueUnit
  >();

  const [selectedResponseProperty, setSelectedResponseProperty] =
    useState<ControlProperty>();

  useEffect(() => {
    const sourceNode = nodes.find((node) => node.id === sourceID);
    const targetNode = nodes.find((node) => node.id === targetID);

    setSource(sourceNode);
    setTarget(targetNode);
  }, [nodes]);

  const handleExitClick = () => {
    setActiveSignalSettings({ id, sourceID, targetID, clientX, clientY });
  };

  // Load initial control signal load for ControlSource
  useEffect(() => {
    const edge = edges.find((ed) => ed.id === id);
    if (edge && source?.type) {
      const allProperties = selectControlProperties(
        source.type as EQUIPMENTTYPE
      );
      let initialSelectedProperty: ControlProperty | undefined =
        allProperties.find(
          (prop) =>
            prop.value ===
            edge.data?.rawControlSignal?.rawControlSource?.triggerProperty
        );
      if (!initialSelectedProperty) {
        [initialSelectedProperty] = allProperties;
      }

      const comparison =
        edge.data?.rawControlSignal?.rawControlSource?.comparison ??
        Comparison.EQUALTO;

      const propertyValue =
        edge.data?.rawControlSignal?.rawControlSource?.triggerValue;

      setSelectedControlProperty(initialSelectedProperty);
      setControlProperties(allProperties);
      setControlPropComparison(comparison);
      setControlPropValue(propertyValue);
    }
  }, [source]);

  // Load initial control signal load for ControlSource
  useEffect(() => {
    const edge = edges.find((ed) => ed.id === id);
    if (edge && target?.type) {
      const allProperties = selectRespondingProperties(
        target.type as EQUIPMENTTYPE
      );
      let initialSelectedProperty: ControlProperty | undefined =
        allProperties.find(
          (prop) =>
            prop.value ===
            edge.data?.rawControlSignal?.rawControlTarget?.setProperty
        );
      if (!initialSelectedProperty) {
        [initialSelectedProperty] = allProperties;
      }

      const propertyValue =
        edge.data?.rawControlSignal?.rawControlTarget?.setValue;

      setSelectedResponseProperty(initialSelectedProperty);
      setResponseProperties(allProperties);
      setResponsePropValue(propertyValue);
    }
  }, [target]);

  // Saves the source to memory upon changes
  useEffect(() => {
    if (!selectedControlProperty) return;
    const controlSource: RawControlSource = {
      id: sourceID,
      type: source?.type as EQUIPMENTTYPE,
      triggerProperty: selectedControlProperty.value,
      triggerValue: controlPropValue ?? {
        bool: { _0: true },
      },
      comparison: controlPropComparsion ?? Comparison.EQUALTO,
    };
    setEdges(
      edges.map((ed) => {
        const newEd = ed;
        const maybeSignal = newEd.data?.rawControlSignal as
          | RawControlSignal
          | undefined;
        if (ed.id === id && maybeSignal) {
          maybeSignal.rawControlSource = controlSource;
        }
        return newEd;
      })
    );
  }, [selectedControlProperty, controlPropValue, controlPropComparsion]);

  // Saves the control target to memory upon changes
  useEffect(() => {
    if (!selectedResponseProperty) return;
    const controlTarget: RawControlTarget = {
      id: targetID,
      type: target?.type as EQUIPMENTTYPE,
      setProperty: selectedResponseProperty.value,
      setValue: responsePropValue as TriggerValueUnit,
    };

    setEdges(
      edges.map((ed) => {
        const newEd = ed;
        const maybeSignal = newEd.data?.rawControlSignal as
          | RawControlSignal
          | undefined;
        if (ed.id === id && maybeSignal) {
          maybeSignal.rawControlTarget = controlTarget;
        }
        return newEd;
      })
    );
  }, [selectedResponseProperty, responsePropValue]);

  return (
    <div
      className={styles.signalSettingsContainer}
      style={{ left: clientX, top: clientY }}
    >
      <div className={styles.titleBox}>
        <h3 className={styles.title}>
          {source?.data?.label ?? 'Source'} to {target?.data?.label ?? 'Target'}{' '}
          Signal
        </h3>
        <FontAwesomeIcon
          icon={faX}
          className={styles.icon}
          onClick={handleExitClick}
        />
      </div>
      <div className={styles.signalBox}>
        <div className={styles.signalDescription}>
          <GroupedComparator
            node={source}
            type={ControllerCase.CONTROL}
            controlInitialValues={{
              selectedProperty: selectedControlProperty,
              setSelectedProperty: setSelectedControlProperty,
              allProperties: controlProperties,
              controlProp: controlPropValue,
              setControlProp: setControlPropValue,
              controlComparison: controlPropComparsion,
              setComparison: setControlPropComparison,
            }}
            responseInitialValues={{
              allProperties: responseProperties,
              selectedProperty: selectedResponseProperty,
              setSelectedProperty: setSelectedResponseProperty,
              setValue: responsePropValue,
              setSetValue: setResponsePropValue,
            }}
          />
          <GroupedComparator
            node={target}
            type={ControllerCase.RESPONSE}
            controlInitialValues={{
              selectedProperty: selectedControlProperty,
              setSelectedProperty: setSelectedControlProperty,
              allProperties: controlProperties,
              controlProp: controlPropValue,
              setControlProp: setControlPropValue,
              controlComparison: controlPropComparsion,
              setComparison: setControlPropComparison,
            }}
            responseInitialValues={{
              allProperties: responseProperties,
              selectedProperty: selectedResponseProperty,
              setSelectedProperty: setSelectedResponseProperty,
              setValue: responsePropValue,
              setSetValue: setResponsePropValue,
            }}
          />
        </div>
        {/* <div className={styles.signalAddIcon}>
            <FontAwesomeIcon icon={faPlus} />
          </div> */}
      </div>
    </div>
  );
}

export default SignalSettings;
