import React, { ChangeEventHandler } from 'react';
import { useReactFlow } from 'reactflow';
import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import { flattenUnitParameters } from '../Utils/flattenParameters';
import UnitParameter from '../../../../common/nodes/unitParameter';

function SourceParameters({
  id,
  params,
}: {
  id: string;
  params: { [key: string]: any };
}) {
  const { setNodes } = useReactFlow();

  const updateData: ChangeEventHandler<HTMLFormElement> = (e) => {
    const flattenedParameters = flattenUnitParameters(e);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              parameters: {
                ...flattenedParameters,
              },
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <section className={styles.middleSection}>
      <form action="" onChange={updateData}>
        <UnitParameter
          title={{ label: 'Flow Rate', id: 'flowRate' }}
          initialUnit={params?.flowRate ?? {}}
          options={[
            { label: 'gal/second', value: 'galPerSecond' },
            { label: 'gal/minute', value: 'galPerMinute' },
            { label: 'gal/hour', value: 'galPerHour' },
            { label: 'liter/second', value: 'litersPerSecond' },
          ]}
        />
        <UnitParameter
          title={{ label: 'Inlet Pressure', id: 'inletPressure' }}
          initialUnit={params?.inletPressure ?? {}}
          options={[
            { label: 'psi', value: 'psi' },
            { label: 'bar', value: 'bar' },
            { label: 'atm', value: 'atm' },
          ]}
        />
        <UnitParameter
          title={{ label: 'Inlet Temperature', id: 'inletTemperature' }}
          initialUnit={params?.inletTemperature ?? {}}
          options={[
            { label: 'kelvin', value: 'kelvin' },
            { label: 'celsius', value: 'celsius' },
            { label: 'fahrenheit', value: 'fahrenheit' },
          ]}
        />
      </form>
    </section>
  );
}

export default SourceParameters;
