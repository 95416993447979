import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../styles/common/dashboard/simulationsSelector.module.scss';
import { SimulationStorage } from '../../../api/types/nodes';

function SimulationsSelector({
  selected,
  setSelected,
}: {
  selected: string | undefined;
  setSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [simIDs, setSimIDs] = useState<string[]>([]);

  useEffect(() => {
    const simStr = localStorage.getItem('simulate');
    if (!simStr) return;
    const simObj = JSON.parse(simStr) as SimulationStorage | undefined;
    if (simObj) {
      setSimIDs(Object.keys(simObj));
    }
  }, []);

  return (
    <aside className={styles.container}>
      <select
        name=""
        id=""
        className={styles.select}
        onChange={(e) => setSelected(e.currentTarget.value)}
        defaultValue={selected}
      >
        {simIDs.map((simID, idx) => (
          <option value={simID} key={simID}>
            Simulation {idx + 1}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faArrowDown} />
    </aside>
  );
}

export default SimulationsSelector;
