export enum TimeSeriesTypes {
  FERMENTATIONTANK = 'fermentationTank',
  VALVE = 'valve',
  PIPE = 'pipe',
}

export interface TimeSeriesReducerAction {
  type: TimeSeriesTypes;
  payload: {
    data: TimeSeriesData<any>;
  };
}

export interface DataPacket<T> {
  data: TimeSeriesData<T>[];
  type: TimeSeriesTypes;
  simID: string;
}

export interface TimeSeriesData<T> {
  id: string;
  time: string;
  value: T;
}

export interface FermentationTankValue {
  currentVolume: Unit;
  currentLoading: {
    name: string;
    role: string;
    concentration: Unit;
  }[];
  totalBiomassProduced: Unit;
  totalProductProduced: Unit;
  totalSubstrateConsumed: Unit;
  totalOxygenConsumed: Unit;
}

export interface PipeValue {
  pressureDrop: Unit;
  isLaminarFlow?: boolean;
  reynoldsNumber?: Unit;
  outletPressure?: Unit;
  outletTemperature?: Unit;
}

export interface ValveValue {
  maxFlowRate?: Unit;
  openness: Unit;
  setPointOpenness: Unit;
  cavitated?: boolean;
  choked?: boolean;
  delPMax?: Unit;
  accumulation: Unit;
}

export interface Unit {
  unit: string;
  value: number;
}

export interface SimulationStorage {
  [key: string]: TimeSeriesDataDictionary;
}

export interface TimeSeriesDataDictionary {
  fermentationTanks: {
    [key: string]: TimeSeriesData<FermentationTankValue>[];
  };
  pipes: {
    [key: string]: TimeSeriesData<PipeValue>[];
  };
  valves: {
    [key: string]: TimeSeriesData<ValveValue>[];
  };
  // Add other specific data types here
}
