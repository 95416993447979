// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';

const signalEdge = /_signal/;

export type RFState = {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setNodes: (nodes: Node[]) => void;
  setEdges: (edges: Edge[]) => void;

  // Determines whether or not to only show signaling
  showSignal: boolean;
  toggleShowSignaling: () => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create<RFState>((set, get) => ({
  nodes: [],
  edges: [],
  showSignal: false,
  toggleShowSignaling() {
    set({
      showSignal: !get().showSignal,
    });
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges).map((ed) => {
        const edge = ed;
        if (
          edge.sourceHandle !== connection.sourceHandle ||
          edge.targetHandle !== connection.targetHandle
        )
          return edge;
        if (signalEdge.test(ed.sourceHandle ?? '')) {
          edge.type = 'signal';
          edge.data = { label: 'Signal', rawControlSignal: {} };
        } else {
          edge.type = 'pipe';
          edge.data = { label: 'Pipe', parameters: {}, type: 'pipe' };
        }
        return edge;
      }),
    });
  },
  setNodes: (nodes: Node[]) => {
    const currentNodes = get().nodes;
    set({ nodes: currentNodes.concat(nodes) });
  },
  setEdges: (edges: Edge[]) => {
    const edgeIDs = edges.map((ele) => ele.id);
    const currentEdges = get().edges.filter(
      (ele) => edgeIDs.indexOf(ele.id) === -1
    );
    set({ edges: currentEdges.concat(edges) });
  },
}));

export default useStore;
