import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
  faHourglass,
  faTimeline,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch, { SwitchSize } from '../../common/toggleSwitch';
import styles from '../../../styles/editor/buttonController/buttonControlBar.module.scss';
import SimulateButton from './simulateButton';
import CalloutBox from '../../common/calloutBox';
import useSimulationStore, {
  SimulationState,
} from '../../../context/editor/store/simulationStore';
import useEquipmentStore, {
  EquipmentState,
} from '../../../context/editor/store/equipmentStore';

function ButtonControlBar() {
  const { menuShowing, toggleMenuShowing } = useEquipmentStore(
    (state: EquipmentState) => ({
      menuShowing: state.menuShowing,
      toggleMenuShowing: state.toggleMenuShowing,
    })
  );

  const [isShowingSettings, setIsShowingSettings] = useState<boolean>(false);

  const [endDateVal, setEndDateVal] = useState<number>(0);
  const [endDateValUnit, setEndDateValUnit] = useState<string>('days');

  const [timeStepVal, setTimeStepVal] = useState<number>(10);
  const [timeStepUnit, setTimeStepUnit] = useState<string>('minutes');

  const {
    dateParams,
    setEndDate,
    setTimeStep,
    initialEndDateVal,
    initialEndDateUnit,
  } = useSimulationStore((state: SimulationState) => ({
    dateParams: state.dateParams,
    setEndDate: state.setEndDate,
    setTimeStep: state.setTimeStep,
    initialEndDateVal: state.endDateVal,
    initialEndDateUnit: state.endDateUnit,
  }));

  useEffect(() => {
    setTimeStep({ value: timeStepVal, unit: timeStepUnit });
  }, [timeStepVal, timeStepUnit]);

  useEffect(() => {
    setEndDate({ value: endDateVal, unit: endDateValUnit });
  }, [endDateVal, endDateValUnit]);

  useEffect(() => {
    if (dateParams) {
      setTimeStepVal(
        // eslint-disable-next-line no-underscore-dangle
        dateParams.timeStep[Object.keys(dateParams.timeStep)[0]]._0
      );
      setEndDateVal(initialEndDateVal);
    }
  }, []);

  return (
    <div className={styles.bar}>
      <ToggleSwitch
        rounded
        size={SwitchSize.LARGE}
        isToggled={menuShowing}
        onToggle={toggleMenuShowing}
      />
      <div className={styles.simulationWrapper}>
        {/* TODO: Remove me after OEDK */}
        <FontAwesomeIcon
          icon={faTrashCan}
          className={styles.trash}
          onClick={(e) => {
            e.stopPropagation();
            localStorage.removeItem('simulate');
          }}
        />
        {isShowingSettings ? (
          <CalloutBox
            location={{
              boxLoc: { left: '-100%', top: '-500%' },
              chevLoc: { bottom: '-11px', right: '15px' },
            }}
          >
            <div className={styles.settingsContent}>
              <div
                className={styles.inputWrapper}
                style={{ marginBottom: '1rem' }}
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="timeStep" className={styles.label}>
                  Time Step *
                </label>
                <div className={styles.valueWrapper}>
                  <FontAwesomeIcon icon={faHourglass} className={styles.icon} />
                  <input
                    type="number"
                    name="timeStep"
                    className={styles.textInput}
                    placeholder="10.0"
                    onChange={(e) => {
                      const floatVal = parseFloat(e.currentTarget.value);
                      if (Number.isNaN(floatVal)) {
                        setTimeStepVal(0);
                      } else {
                        setTimeStepVal(floatVal);
                      }
                    }}
                    defaultValue={
                      // eslint-disable-next-line no-underscore-dangle
                      dateParams.timeStep[Object.keys(dateParams.timeStep)[0]]
                        ._0
                    }
                  />
                  <select
                    name=""
                    id=""
                    className={styles.unit}
                    onChange={(e) => setTimeStepUnit(e.currentTarget.value)}
                    defaultValue={timeStepUnit}
                  >
                    <option value="minutes">min</option>
                    <option value="seconds">sec</option>
                    <option value="hours">hour</option>
                    <option value="days">day</option>
                  </select>
                </div>
              </div>
              <div className={styles.inputWrapper}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="timeStep" className={styles.label}>
                  Length *
                </label>
                <div className={styles.valueWrapper}>
                  <FontAwesomeIcon icon={faTimeline} className={styles.icon} />
                  <input
                    type="number"
                    className={styles.textInput}
                    placeholder="9.0"
                    onChange={(e) => {
                      const floatVal = parseFloat(e.currentTarget.value);
                      if (Number.isNaN(floatVal)) {
                        setEndDateVal(0);
                      } else {
                        setEndDateVal(floatVal);
                      }
                    }}
                    defaultValue={initialEndDateVal}
                  />
                  <select
                    name=""
                    id=""
                    className={styles.unit}
                    onChange={(e) => {
                      setEndDateValUnit(e.currentTarget.value);
                    }}
                    defaultValue={initialEndDateUnit}
                  >
                    <option value="days">day</option>
                    <option value="hours">hour</option>
                    <option value="minutes">min</option>
                    <option value="seconds">sec</option>
                  </select>
                </div>
              </div>
            </div>
          </CalloutBox>
        ) : null}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          style={{ padding: '0', margin: '0', outline: 'none', border: 'none' }}
          aria-label="Opens the simulation settings including TimeStep and total simulation time."
        >
          <FontAwesomeIcon
            icon={faGear}
            size="2x"
            className={styles.settingsIcon}
            onClick={() => setIsShowingSettings(!isShowingSettings)}
          />
        </button>
        <SimulateButton />
      </div>
    </div>
  );
}

export default ButtonControlBar;
