/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../styles/editor/buttonController/simulateButton.module.scss';
import SocketTypes from '../../../api/types/socketTypes';
import websocketContext from '../../../context/app/websocket-context';
import useStore from '../../../context/editor/store/store';
import useSimulationStore, {
  SimulationState,
} from '../../../context/editor/store/simulationStore';

const signalEdge = /_signal/;

function SimulateButton() {
  const { socketClient } = useContext(websocketContext);
  const { toggleSimulating, simulating, dateParams } = useSimulationStore(
    (state: SimulationState) => ({
      toggleSimulating: state.toggleSimulating,
      simulating: state.simulating,
      dateParams: state.dateParams,
    })
  );
  const { nodes, edges } = useStore((state) => ({
    nodes: state.nodes,
    edges: state.edges,
  }));

  function handleClick() {
    const signals = edges
      .filter((ed) => signalEdge.test(ed.sourceHandle ?? ''))
      .map((signal) => signal.data?.rawControlSignal)
      .filter((s) => s);
    const pipes = edges.filter((ed) => !signalEdge.test(ed.sourceHandle ?? ''));

    console.log(dateParams);

    socketClient?.emit(SocketTypes.SIMULATE, {
      nodes,
      edges: pipes,
      signals,
      dateParams,
    });
    toggleSimulating();
  }

  return (
    <button
      type="button"
      className={styles.buttonWrapper}
      aria-label="simulate"
      onClick={handleClick}
    >
      {!simulating ? (
        <FontAwesomeIcon icon={faBolt} size="sm" className={styles.icon} />
      ) : (
        <div className={styles.ldsEllipsis}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      <p className={styles.text}>Simulate</p>
    </button>
  );
}

export default SimulateButton;
