import React from 'react';
import {
  EdgeProps,
  MarkerType,
  getMarkerEnd,
  getSmoothStepPath,
} from 'reactflow';
import useStore, { RFState } from '../../../../context/editor/store/store';
import usePipeStore, {
  PipeState,
} from '../../../../context/editor/store/pipeStore';

function PipeEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
  sourcePosition,
  targetPosition,
}: EdgeProps) {
  const { isSignalShowing, edges } = useStore((state: RFState) => ({
    isSignalShowing: state.showSignal,
    edges: state.edges,
  }));

  const { setActivePipeSettings } = usePipeStore((state: PipeState) => ({
    setActivePipeSettings: state.setActivePipeSettings,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [path, labelX, labelY, offsetX, offsetY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(MarkerType.Arrow);

  const handleClick = (e: React.MouseEvent) => {
    const edge = edges.find((ed) => ed.id === id);
    const { clientX, clientY } = e;
    setActivePipeSettings({
      id,
      params: data.parameters,
      clientX,
      clientY,
      sourceID: edge?.source ?? '',
      targetID: edge?.target ?? '',
    });
  };

  return (
    <>
      <path
        id="pipe-line-outline-path"
        d={path}
        stroke="#233d7c"
        strokeWidth="15px"
        markerEnd={markerEnd}
        style={
          !isSignalShowing
            ? {}
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
        onDoubleClick={handleClick}
      />
      <path
        id="pipe-line-inner-path"
        d={path}
        stroke="#f5f4ef"
        strokeWidth="10px"
        markerEnd={markerEnd}
        style={
          !isSignalShowing
            ? {}
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
        onDoubleClick={handleClick}
      />
      <style>{`
    #pipe-line-outline-path,
    #pipe-line-inner-path {
      stroke-linecap: round;
      fill: transparent;
    }
  `}</style>
    </>
  );
}

export default PipeEdge;
