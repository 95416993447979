import {
  fermTankTriggerValues,
  fermTankSetProperties,
} from '../../../../constants/signals/fermTankData';
import {
  valveSetProperties,
  valveTriggerProperties,
} from '../../../../constants/signals/valveData';
import EQUIPMENTTYPE from '../../../../types/equipmentTypes';
import {
  ControlProperty,
  SignalValueType,
} from '../../../../types/signalTypes';

export function selectControlProperties(
  from: EQUIPMENTTYPE
): ControlProperty[] {
  switch (from) {
    case EQUIPMENTTYPE.FermentationTank: {
      const values = fermTankTriggerValues.map((value) => ({
        value: value.triggerProperty,
        label: value.label,
        type: value.type,
        options: value.options,
      }));
      return values;
    }
    case EQUIPMENTTYPE.Valve: {
      const values = valveTriggerProperties.map((value) => ({
        value: value.triggerProperty,
        label: value.label,
        type: value.type,
        options: value.options,
      }));
      return values;
    }
    default:
      return [];
  }
}

export function selectRespondingProperties(
  from: EQUIPMENTTYPE
): ControlProperty[] {
  switch (from) {
    case EQUIPMENTTYPE.FermentationTank: {
      const values = fermTankSetProperties.map((value) => ({
        value: value.setProperty,
        label: value.label,
        type: value.type,
        options: value.options,
      }));
      return values;
    }
    case EQUIPMENTTYPE.Valve: {
      const values = valveSetProperties.map((value) => ({
        value: value.setProperty,
        label: value.label,
        type: value.type,
        options: value.options,
      }));
      return values;
    }
    default:
      return [];
  }
}

export function selectedComparisonType(type: SignalValueType): {
  value: string;
  label: string;
}[] {
  switch (type) {
    case SignalValueType.BOOLEAN: {
      return [
        {
          value: 'equalTo',
          label: '=',
        },
      ];
    }
    case SignalValueType.UNIT: {
      return [
        {
          value: 'equalTo',
          label: '=',
        },
        {
          value: 'greaterThan',
          label: '>',
        },
        {
          value: 'lessThan',
          label: '<',
        },
        {
          value: 'greaterThanOrEqualTo',
          label: '>=',
        },
        {
          value: 'lessThanOrEqualTo',
          label: '<=',
        },
      ];
    }
    default:
      return [];
  }
}
