export const SUCROSE = {
  Z: 1e-6,
  criticalPressure: {
    value: 218.12396,
    unit: 'bar',
  },
  A: 6.20963,
  fluidRole: 'substrate',
  massFrac: 0.2,
  dof: 6,
  density: {
    value: 1.5074366,
    unit: 'gramsPerCubicCentimeter',
  },
  concentration: {
    unit: 'gramsPerLiter',
    value: 150,
  },
  B: 2354.731,
  viscosity: {
    unit: 'centipoise',
    value: 48.63,
  },
  volumeFrac: 0.13911821,
  moleFrac: 0.012986648,
  molecularWeight: 342.30008,
  C: 7.559,
  name: 'Sucrose',
};

export const WATER = {
  fluidRole: 'spectator',
  B: 2354.731,
  viscosity: {
    unit: 'centipoise',
    value: 1.51,
  },
  density: {
    unit: 'gramsPerCubicCentimeter',
    value: 0.992827,
  },
  massFrac: 0.8,
  concentration: {
    unit: 'gramsPerLiter',
    value: 0,
  },
  C: 7.559,
  name: 'Water',
  moleFrac: 0.98701334,
  A: 6.20963,
  volumeFrac: 0.8494189,
  molecularWeight: 18.01528,
  dof: 6,
  criticalPressure: {
    value: 218.12396,
    unit: 'bar',
  },
  Z: 1e-6,
};

export const CITRICACID = {
  Z: 1e-6,
  massFrac: 1,
  molecularWeight: 210.14,
  A: 6.20963,
  density: {
    value: 1.542,
    unit: 'gramsPerCubicCentimeter',
  },
  concentration: {
    unit: 'gramsPerLiter',
    value: 39.01143,
  },
  name: 'Citric Acid',
  fluidRole: 'product',
  C: 7.559,
  viscosity: {
    unit: 'centipoise',
    value: 6.5,
  },
  dof: 6,
  B: 2354.731,
  criticalPressure: {
    value: 3200.1,
    unit: 'psi',
  },
  moleFrac: 1,
  volumeFrac: 1,
};

export const ASPERGILLUSNIGER = {
  moleFrac: 1,
  volumeFrac: 1,
  C: 7.559,
  fluidRole: 'biomass',
  massFrac: 1,
  name: 'A. niger',
  molecularWeight: 23.8146,
  A: 6.20963,
  concentration: {
    unit: 'gramsPerLiter',
    value: 76.84352,
  },
  criticalPressure: {
    value: 3200.1,
    unit: 'psi',
  },
  Z: 1e-6,
  B: 2354.731,
  density: {
    value: 1041.667,
    unit: 'kilogramPerCubicMeter',
  },
  viscosity: {
    value: 0.89,
    unit: 'centipoise',
  },
  dof: 6,
};
