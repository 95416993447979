import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Chart as ChartJS, Colors, Tooltip, ChartOptions } from 'chart.js';

import { Line } from 'react-chartjs-2';
import LeftSelectionBar from '../leftBar';
import {
  SimulationStorage,
  TimeSeriesData,
  ValveValue,
} from '../../../api/types/nodes';

import styles from '../../../styles/dashboard/dashboard.module.scss';
import GraphHeader from '../../common/dashboards/graphHeader';
import GraphSelector from '../../common/dashboards/graphSelector';

import 'chartjs-adapter-luxon';
import DataContainer from '../../common/dashboards/dataContainer';
import {
  calculateAverage,
  calculateAvgROC,
  calculateMax,
  calculateMaxROC,
  calculateMin,
  calculateMinROC,
  calculateTotalChange,
} from '../../common/utils/dashUtils';
import SimulationsSelector from '../../common/dashboards/simulations';

ChartJS.register(Colors, Tooltip);

const opennessOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Openness (Percentage)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const setpointOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Set Point Openness (Percentage)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
const accumulation: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Openness (Percentage)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

function retrieveData(
  id: string,
  simID: string | undefined
): TimeSeriesData<ValveValue>[] {
  const allDataStr = localStorage.getItem('simulate');
  if (!allDataStr) return [];
  const allData: SimulationStorage = JSON.parse(allDataStr);
  if (simID) {
    return allData[simID]?.valves[id] ?? [];
  }
  const recentSim = localStorage.getItem('simID') as string;
  return recentSim ? allData[recentSim]?.valves[id] ?? [] : [];
}

function ValveDashboard() {
  const { nodeId } = useParams();
  const [params] = useSearchParams();
  const [selectedSim, setSelectedSim] = useState<string | undefined>(undefined);

  // const [highlightedPoint, setHighlightedPoint] = useState(null);

  const [data, setData] = useState<TimeSeriesData<ValveValue>[]>([]);

  const opennessData = {
    labels: data?.map((row) =>
      DateTime.fromISO(row.time).toFormat('MMM d, h:mm a')
    ),
    datasets: [
      {
        label: `Openness in ${data?.pop()?.value?.openness?.unit}`,
        data: data?.map((row) => row.value?.openness?.value),
      },
    ],
  };

  const accumulationData = {
    labels: data?.map((row) =>
      DateTime.fromISO(row.time).toFormat('MMM d, h:mm a')
    ),
    datasets: [
      {
        label: `Accumulation in ${data?.pop()?.value?.accumulation?.unit}`,
        data: data?.map((row) => row.value?.accumulation?.value),
      },
    ],
  };
  const setpointData = {
    labels: data?.map((row) =>
      DateTime.fromISO(row.time).toFormat('MMM d, h:mm a')
    ),
    datasets: [
      {
        label: `Set Point Openness in ${
          data?.pop()?.value?.setPointOpenness?.unit
        }`,
        data: data?.map((row) => row.value?.setPointOpenness?.value),
      },
    ],
  };

  useEffect(() => {
    if (nodeId) {
      setData(retrieveData(nodeId, selectedSim));
    }
  }, [nodeId, params]);

  return (
    <main className={styles.dashboard}>
      <LeftSelectionBar />
      <section className={styles.statsSection}>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Openness"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/volumeLevel.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Openness' }}
            secondaries={[{ label: 'Openness' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={opennessData} options={opennessOptions} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Total Openness Change',
                value: calculateTotalChange(
                  data?.map((datem) => datem.value?.openness) ?? []
                ),
                unit: '%',
              },
              { label: 'Openness Stability', value: 'Stable', unit: '' },
            ]}
            middle={[
              {
                label: 'Maximum Openness',
                value: calculateMax(
                  data?.map((datem) => datem.value?.openness) ?? []
                ).value,
                unit: '%',
              },
              {
                label: 'Minimum Openness',
                value: calculateMin(
                  data?.map((datem) => datem.value?.openness) ?? []
                ).value,
                unit: '%',
              },
              {
                label: 'Maximum Rate of Change',
                value: calculateMaxROC(
                  data?.map((datem) => datem.value?.openness) ?? []
                ).value,
                unit: '%/s',
              },
              {
                label: 'Minimum Rate of Change',
                value: calculateMinROC(
                  data?.map((datem) => datem.value?.openness) ?? []
                ).value,
                unit: '%/s',
              },
            ]}
            right={[
              {
                label: 'Average Openness',
                value: calculateAverage(
                  data?.map((datem) => datem.value?.openness) ?? []
                ),
                unit: '%',
              },
              {
                label: 'Average Rate of Change',
                value: calculateAvgROC(
                  data?.map((datem) => datem.value?.openness) ?? []
                ),
                unit: '%/s',
              },
            ]}
          />
        </div>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Accumulation"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/accumulation.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Accumulation' }}
            secondaries={[{ label: 'Accumulation' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={accumulationData} options={accumulation} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Total Accumulation Change',
                value: calculateTotalChange(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal',
              },
              { label: 'Accumulation Stability', value: 'Stable', unit: '' },
            ]}
            middle={[
              {
                label: 'Maximum Accumulation',
                value: calculateMax(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal',
              },
              {
                label: 'Minimum Accumulation',
                value: calculateMin(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal',
              },
              {
                label: 'Maximum Rate of Change',
                value: calculateMaxROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal/10 min',
              },
              {
                label: 'Minimum Rate of Change',
                value: calculateMinROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal/10 min',
              },
            ]}
            right={[
              {
                label: 'Average Accumulation',
                value: calculateAverage(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal',
              },
              {
                label: 'Average Rate of Change',
                value: calculateAvgROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal/10 min',
              },
            ]}
          />
        </div>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Set Point Openness"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/accumulation.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Set Point Openness' }}
            secondaries={[{ label: 'Set Point Openness' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={setpointData} options={setpointOptions} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Total Set Point Openness Change',
                value: calculateTotalChange(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal',
              },
              {
                label: 'Set Point Openness Stability',
                value: 'Stable',
                unit: '',
              },
            ]}
            middle={[
              {
                label: 'Maximum Set Point Openness',
                value: calculateMax(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal',
              },
              {
                label: 'Minimum Set Point Openness',
                value: calculateMin(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal',
              },
              {
                label: 'Maximum Rate of Change',
                value: calculateMaxROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal/10 min',
              },
              {
                label: 'Minimum Rate of Change',
                value: calculateMinROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ).value,
                unit: 'gal/10 min',
              },
            ]}
            right={[
              {
                label: 'Average Set Point Openness',
                value: calculateAverage(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal',
              },
              {
                label: 'Average Rate of Change',
                value: calculateAvgROC(
                  data?.map((datem) => datem.value?.accumulation) ?? []
                ),
                unit: 'gal/10 min',
              },
            ]}
          />
        </div>
      </section>
      <SimulationsSelector
        selected={selectedSim}
        setSelected={setSelectedSim}
      />
    </main>
  );
}

export default ValveDashboard;
