// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand';

export type EquipmentState = {
  menuShowing: boolean;
  toggleMenuShowing: () => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useEquipmentStore = create<EquipmentState>((set, get) => ({
  menuShowing: true,
  toggleMenuShowing() {
    set({
      menuShowing: !get().menuShowing,
    });
  },
}));

export default useEquipmentStore;
