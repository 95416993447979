import React from 'react';
import styles from '../../styles/common/calloutBox.module.scss';

interface CalloutBoxProps {
  boxLoc: {
    top: string;
    left: string;
  };
  chevLoc: {
    bottom: string;
    right: string;
  };
}

function CalloutBox({
  location,
  children,
}: {
  location: CalloutBoxProps;
  children: JSX.Element;
}) {
  return (
    <div
      className={styles.box}
      style={{ left: location.boxLoc.left, top: location.boxLoc.top }}
    >
      <div
        className={styles.chevron}
        style={{
          right: location.chevLoc.right,
          bottom: location.chevLoc.bottom,
        }}
      />
      {children}
    </div>
  );
}

export default CalloutBox;
