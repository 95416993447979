import React from 'react';
import { TimeSeriesDataDictionary } from '../../api/types/nodes';
import WebSocketState from '../../types/context/webSocket';

export const initialTimeSeries: TimeSeriesDataDictionary = {
  fermentationTanks: {},
  pipes: {},
  valves: {},
};

export default React.createContext<WebSocketState>({
  socketClient: null,
  timeSeries: initialTimeSeries,
  timeSeriesReducer: () => {},
});
