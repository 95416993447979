import React from 'react';
import tankStyles from '../../../styles/editor/canvas/nodes/tank.module.scss';

function TankParameterTypeSelector({
  setParamType,
  param,
}: {
  setParamType: React.Dispatch<React.SetStateAction<string>>;
  param: string;
}) {
  return (
    <select
      name="units"
      id="units"
      className={tankStyles.category}
      onChange={(e) => setParamType(e.currentTarget.value)}
      defaultValue={param}
    >
      <option value="kinetic">Kinetics</option>
      <option value="generic">Generic</option>
      <option value="mixture">Mixture</option>
    </select>
  );
}

export default TankParameterTypeSelector;
