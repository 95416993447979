import React, { useState, ChangeEventHandler } from 'react';
import { Instance } from 'reactflow';
import { v4 } from 'uuid';
import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import {
  flattenUnitParameters,
  flattenEnumParameters,
  flattenTimeParameters,
  flattenBooleanParameters,
  FluidParameters,
} from '../Utils/flattenParameters';

import GenericParameters from './Parameters/GenericParameters';
import KineticParameters from './Parameters/KineticParameters';
import MixtureParameters from './Parameters/MixtureParameters';
import FLUIDROLE from '../../../../../types/fluidTypes';
import {
  SUCROSE,
  CITRICACID,
  ASPERGILLUSNIGER,
} from '../../../../../constants/nodes/fermentationTank/fluids';

function FermentationTankParameters({
  setNodes,
  genericParams,
  kineticParams,
  mixtureParams,
  id,
}: {
  setNodes: Instance.SetNodes<any>;
  id: string;
  genericParams: {
    [key: string]: any;
  };
  kineticParams: {
    all: {
      _0: {
        [key: string]: any;
      };
    };
  };
  mixtureParams: {
    fluids: {
      [key: string]: any;
      concentration: {
        value: string;
        unit: string;
      };
      fluidRole: FLUIDROLE;
    }[];
  };
}) {
  const [paramType, setParamType] = useState('generic');

  const updateData: ChangeEventHandler<HTMLFormElement> = (e) => {
    switch (paramType) {
      case 'kinetic': {
        const flattenedUnitParameters = flattenUnitParameters(e);
        const flattenedEnumParameters = flattenEnumParameters(e);
        setNodes((nodes) =>
          nodes.map((node) => {
            if (node.id === id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  parameters: {
                    ...node.data.parameters,
                    kinetics: {
                      all: {
                        _0: {
                          // eslint-disable-next-line no-underscore-dangle
                          ...node.data.parameters?.kinetics?.all?._0,
                          ...flattenedEnumParameters,
                          ...flattenedUnitParameters,
                        },
                      },
                    },
                  },
                },
              };
            }

            return node;
          })
        );
        return;
      }
      case 'generic': {
        const flattenedParameters = flattenUnitParameters(e);
        const flattenedGenericEnumParameters = flattenEnumParameters(e);
        const flattenedTimeParameters = flattenTimeParameters(e);
        const flattenedBoolParameters = flattenBooleanParameters(e);

        setNodes((nodes) =>
          nodes.map((node) => {
            if (node.id === id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  parameters: {
                    ...node.data.parameters,
                    ...flattenedParameters,
                    ...flattenedGenericEnumParameters,
                    ...flattenedTimeParameters,
                    ...flattenedBoolParameters,
                  },
                },
              };
            }

            return node;
          })
        );
        break;
      }
      case 'mixture':
        {
          const elements = Array.from(e.currentTarget.elements);
          const fieldsets = elements.filter(
            (ele) => ele.tagName === 'FIELDSET'
          );
          const parameters = fieldsets
            .map((fieldset): FluidParameters | undefined => {
              const ID = fieldset.id;

              const valueElement = fieldset.querySelector('#value');
              if (!valueElement) return undefined;
              const { value } = valueElement as HTMLInputElement;

              const unitElement = fieldset.querySelector('#unit');
              if (!unitElement) return undefined;
              const unit = (unitElement as HTMLSelectElement).value;

              // If it's not a unit, don't add it.
              if (!value || !unit) return undefined;

              const floatValue = parseFloat(value);

              switch (ID) {
                case FLUIDROLE.SUBSTRATE: {
                  return {
                    ...SUCROSE,
                    concentration: {
                      unit,
                      value: floatValue,
                    },
                    id: v4(),
                  };
                }
                case FLUIDROLE.BIOMASS: {
                  return {
                    ...ASPERGILLUSNIGER,
                    concentration: {
                      unit,
                      value: floatValue,
                    },
                    id: v4(),
                  };
                }
                case FLUIDROLE.PRODUCT: {
                  return {
                    ...CITRICACID,
                    concentration: {
                      unit,
                      value: floatValue,
                    },
                    id: v4(),
                  };
                }
                default:
                  return undefined;
              }
            })
            .filter(
              // Remove all of the undefined values. This is a pseudo-compactMap
              (n: FluidParameters | undefined) => n
            ) as FluidParameters[];

          setNodes((nodes) =>
            nodes.map((node) => {
              if (node.id === id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    parameters: {
                      ...node.data.parameters,
                      currentLoading: parameters,
                      mixture: {
                        fluids: parameters,
                      },
                    },
                  },
                };
              }

              return node;
            })
          );
        }
        break;
      default:
    }
  };

  switch (paramType) {
    case 'generic':
      return (
        <section className={styles.middleSection}>
          <GenericParameters
            setParamType={setParamType}
            param={paramType}
            updateData={updateData}
            generics={genericParams}
          />
        </section>
      );
    case 'kinetic':
      return (
        <section className={styles.middleSection}>
          <KineticParameters
            setParamType={setParamType}
            param={paramType}
            updateData={updateData}
            kinetics={kineticParams}
          />
        </section>
      );
    case 'mixture':
      return (
        <section className={styles.middleSection}>
          <MixtureParameters
            setParamType={setParamType}
            param={paramType}
            updateData={updateData}
            mixture={mixtureParams}
          />
        </section>
      );
    default:
      return (
        <section className={styles.middleSection}>
          <GenericParameters
            setParamType={setParamType}
            param={paramType}
            updateData={updateData}
            generics={genericParams}
          />
        </section>
      );
  }
}

export default FermentationTankParameters;
