import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Chart as ChartJS, Colors, Tooltip, ChartOptions } from 'chart.js';

import { Line } from 'react-chartjs-2';
import LeftSelectionBar from '../leftBar';
import {
  TimeSeriesData,
  FermentationTankValue,
  SimulationStorage,
} from '../../../api/types/nodes';

import styles from '../../../styles/dashboard/dashboard.module.scss';
import GraphHeader from '../../common/dashboards/graphHeader';
import GraphSelector from '../../common/dashboards/graphSelector';

import 'chartjs-adapter-luxon';
import DataContainer from '../../common/dashboards/dataContainer';
import {
  calculateAverage,
  calculateAvgROC,
  calculateMax,
  calculateMaxROC,
  calculateMin,
  calculateMinROC,
  calculateTotalChange,
} from '../../common/utils/dashUtils';
// import SimulationsSelector from '../../common/dashboards/simulations';

ChartJS.register(Colors, Tooltip);

const options: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Current Volume (liters)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const concentrationOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Concentration (grams/liters)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const productionDataOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time',
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Total Produced/Consumed (grams)',
      },
      ticks: {
        stepSize: 2000,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

function retrieveData(
  id: string,
  simID: string | undefined
): TimeSeriesData<FermentationTankValue>[] {
  const allDataStr = localStorage.getItem('simulate');
  if (!allDataStr) return [];
  const allData: SimulationStorage = JSON.parse(allDataStr);
  if (simID) {
    return allData[simID]?.fermentationTanks[id] ?? [];
  }
  const recentSim = localStorage.getItem('simID') as string;
  return recentSim ? allData[recentSim]?.fermentationTanks[id] ?? [] : [];
}

function FermentationTankDashboard() {
  const { nodeId } = useParams();
  const [params] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSim, setSelectedSim] = useState<string | undefined>(undefined);

  // const [highlightedPoint, setHighlightedPoint] = useState(null);

  const [data, setData] = useState<TimeSeriesData<FermentationTankValue>[]>([]);

  const volumeData = {
    labels: data?.map((row) =>
      DateTime.fromISO(row.time).toFormat('MMM d, h:mm a')
    ),
    datasets: [
      {
        label: `Current Volume in ${data?.pop()?.value?.currentVolume.unit}`,
        data: data?.map((row) => row.value?.currentVolume?.value),
        // pointStyle: (context) => {
        //   // if (!highlightedPoint) return 'circle';
        //   // const { dataIndex } = context;
        //   // const isHighlighted = highlightedPoint.dataIndex === dataIndex;
        //   // return isHighlighted ? 'star' : 'circle';
        // },
      },
    ],
  };

  const concentrationData = {
    labels: data?.map((row) => DateTime.fromISO(row.time).toFormat('DD HH:mm')),
    datasets: [
      {
        label: 'Concentration of Substrate',
        data: data?.map(
          (
            datum: TimeSeriesData<FermentationTankValue>
          ): number | undefined => {
            const substrate = datum.value?.currentLoading?.filter(
              (load) => load.role === 'substrate'
            );
            if (!substrate) return undefined;
            const lastSubstrate = substrate[0];
            return lastSubstrate?.concentration?.value;
          }
        ),
      },
      {
        label: 'Concentration of Product',
        data: data?.map(
          (
            datum: TimeSeriesData<FermentationTankValue>
          ): number | undefined => {
            const substrate = datum.value?.currentLoading?.filter(
              (load) => load.role === 'product'
            );
            if (!substrate) return undefined;
            const lastSubstrate = substrate[0];
            return lastSubstrate?.concentration?.value;
          }
        ),
      },
      {
        label: 'Concentration of Biomass',
        data: data?.map(
          (
            datum: TimeSeriesData<FermentationTankValue>
          ): number | undefined => {
            const substrate = datum.value?.currentLoading?.filter(
              (load) => load.role === 'biomass'
            );
            if (!substrate) return undefined;
            const lastSubstrate = substrate[0];
            return lastSubstrate?.concentration?.value;
          }
        ),
      },
    ],
  };

  const productionData = {
    labels: data?.map((row) => DateTime.fromISO(row.time).toFormat('DD HH:mm')),
    datasets: [
      // {
      //   label: 'Total Biomass Produced (grams)',
      //   data: data?.map(
      //     (
      //       datum: TimeSeriesData<FermentationTankValue>
      //     ): number | undefined => {
      //       const substrate = datum.value?.totalBiomassProduced?.value;
      //       if (!substrate) return undefined;
      //       return substrate;
      //     }
      //   ),
      // },
      // {
      //   label: 'Total Product Produced (grams)',
      //   data: data?.map(
      //     (
      //       datum: TimeSeriesData<FermentationTankValue>
      //     ): number | undefined => {
      //       const substrate = datum.value?.totalProductProduced?.value;
      //       if (!substrate) return undefined;
      //       return substrate;
      //     }
      //   ),
      // },
      // {
      //   label: 'Total Substrate Consumed (grams)',
      //   data: data?.map(
      //     (
      //       datum: TimeSeriesData<FermentationTankValue>
      //     ): number | undefined => {
      //       const substrate = datum.value?.totalSubstrateConsumed?.value;
      //       if (!substrate) return undefined;
      //       return substrate;
      //     }
      //   ),
      // },
      {
        label: 'Total Oxygen Consumed (grams)',
        data: data?.map(
          (
            datum: TimeSeriesData<FermentationTankValue>
          ): number | undefined => {
            const substrate = datum.value?.totalOxygenConsumed?.value;
            if (!substrate) return undefined;
            return substrate;
          }
        ),
      },
    ],
  };

  useEffect(() => {
    if (nodeId) {
      setData(retrieveData(nodeId, selectedSim));
    }
  }, [nodeId, params, selectedSim]);

  return (
    <main className={styles.dashboard}>
      <LeftSelectionBar />
      <section className={styles.statsSection}>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Current Volume"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/volumeLevel.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Current Volume' }}
            secondaries={[{ label: 'Current Volume' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={volumeData} options={options} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Total Volume Change',
                value: calculateTotalChange(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ),
                unit: 'L',
              },
              { label: 'Volume Stability', value: 'Stable', unit: '' },
            ]}
            middle={[
              {
                label: 'Maximum Volume',
                value: calculateMax(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ).value,
                unit: 'L',
              },
              {
                label: 'Minimum Volume',
                value: calculateMin(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ).value,
                unit: 'L',
              },
              {
                label: 'Maximum Rate of Change',
                value: calculateMaxROC(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ).value,
                unit: 'L/s',
              },
              {
                label: 'Minimum Rate of Change',
                value: calculateMinROC(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ).value,
                unit: 'L/s',
              },
            ]}
            right={[
              {
                label: 'Average Volume',
                value: calculateAverage(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ),
                unit: 'L',
              },
              {
                label: 'Average Rate of Change',
                value: calculateAvgROC(
                  data?.map((datem) => datem.value?.currentVolume) ?? []
                ),
                unit: 'L/s',
              },
            ]}
          />
        </div>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Concentrations"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/concentration.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Concentration' }}
            secondaries={[{ label: 'Concentration' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={concentrationData} options={concentrationOptions} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Maximum Substrate',
                value: calculateMax(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'substrate'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Minimum Substrate',
                value: calculateMin(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'substrate'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Maximum Rate of Change Substrate',
                value: calculateMaxROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'substrate'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
              {
                label: 'Minimum Rate of Change Substrate',
                value: calculateMinROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'substrate'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
            ]}
            middle={[
              {
                label: 'Maximum Product',
                value: calculateMax(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'product'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Minimum Product',
                value: calculateMin(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'product'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Maximum Rate of Change Product',
                value: calculateMaxROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'product'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
              {
                label: 'Minimum Rate of Change Product',
                value: calculateMinROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'product'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
            ]}
            right={[
              {
                label: 'Maximum Biomass',
                value: calculateMax(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'biomass'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Minimum Biomass',
                value: calculateMin(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'biomass'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L',
              },
              {
                label: 'Maximum Rate of Change Biomass',
                value: calculateMaxROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'biomass'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
              {
                label: 'Minimum Rate of Change Biomass',
                value: calculateMinROC(
                  (() => {
                    const substrate = data?.flatMap((datem) =>
                      datem.value?.currentLoading.filter(
                        (ele) => ele.role === 'biomass'
                      )
                    );
                    return substrate.map((s) => s.concentration);
                  })()
                ).value,
                unit: 'g/L-s',
              },
            ]}
          />
        </div>
        <div className={styles.statsCard}>
          <GraphHeader
            title="Kinetic Production"
            icon={{
              url: 'https://untanglify-images.s3.amazonaws.com/concentration.svg',
            }}
            dates={{
              startDate: DateTime.fromISO(data?.shift()?.time ?? ''),
              endDate: DateTime.fromISO(data?.pop()?.time ?? ''),
            }}
          />
          <GraphSelector
            main={{ label: 'Kinetic Production' }}
            secondaries={[{ label: 'Kinetic Production' }]}
          />
          <div className={styles.chartContainer}>
            <Line data={productionData} options={productionDataOptions} />
          </div>
          <DataContainer
            left={[
              {
                label: 'Maximum Substrate Consumed',
                value: calculateMax(
                  data?.map((ele) => ele.value?.totalSubstrateConsumed) ?? [],
                  true
                ).value,
                unit: 'g',
              },
              {
                label: 'Minimum Substrate Consumed',
                value: calculateMin(
                  data?.map((ele) => ele.value?.totalSubstrateConsumed) ?? [],
                  true
                ).value,
                unit: 'g',
              },
              {
                label: 'Maximum Rate of Change',
                value: calculateMaxROC(
                  data?.map((ele) => ele.value?.totalSubstrateConsumed) ?? [],
                  true
                ).value,
                unit: 'g/s',
              },
              {
                label: 'Minimum Rate of Change',
                value: calculateMinROC(
                  data?.map((ele) => ele.value?.totalSubstrateConsumed) ?? []
                ).value,
                unit: 'g/s',
              },
            ]}
            middle={[
              {
                label: 'Maximum Product Produced',
                value: calculateMax(
                  data?.map((ele) => ele.value?.totalProductProduced) ?? []
                ).value,
                unit: 'g',
              },
              {
                label: 'Minimum Product Produced',
                value: calculateMin(
                  data?.map((ele) => ele.value?.totalProductProduced) ?? []
                ).value,
                unit: 'g',
              },
              {
                label: 'Maximum Rate of Change Product Produced',
                value: calculateMaxROC(
                  data?.map((ele) => ele.value?.totalProductProduced) ?? []
                ).value,
                unit: 'g/s',
              },
              {
                label: 'Minimum Rate of Change Product Produced',
                value: calculateMinROC(
                  data?.map((ele) => ele.value?.totalProductProduced) ?? []
                ).value,
                unit: 'g/s',
              },
            ]}
            right={[
              {
                label: 'Maximum Biomass Produced',
                value: calculateMax(
                  data?.map((ele) => ele.value?.totalBiomassProduced) ?? []
                ).value,
                unit: 'g',
              },
              {
                label: 'Minimum Biomass Produced',
                value: calculateMin(
                  data?.map((ele) => ele.value?.totalBiomassProduced) ?? []
                ).value,
                unit: 'g',
              },
              {
                label: 'Maximum Rate of Change Biomass Produced',
                value: calculateMaxROC(
                  data?.map((ele) => ele.value?.totalBiomassProduced) ?? []
                ).value,
                unit: 'g/s',
              },
              {
                label: 'Minimum Rate of Change Biomass Produced ',
                value: calculateMinROC(
                  data?.map((ele) => ele.value?.totalBiomassProduced) ?? []
                ).value,
                unit: 'g/s',
              },
            ]}
          />
        </div>
      </section>
      {/* <SimulationsSelector
        selected={selectedSim}
        setSelected={setSelectedSim}
      /> */}
    </main>
  );
}

export default FermentationTankDashboard;
