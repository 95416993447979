import React, { useCallback, useEffect, useRef } from 'react';

import ReactFlow, {
  Background,
  BackgroundVariant,
  useReactFlow,
} from 'reactflow';

import { Link } from 'react-router-dom';
import Pipe from './nodes/Pipe/Pipe';
import FermentationTank from './nodes/FermentationTank/FermentationTank';
import Valve from './nodes/Valve/Valve';

import styles from '../../../styles/editor/canvas/canvas.module.scss';
import Minimap from './minimap';
import Source from './nodes/Source/Source';
import useStore, { RFState } from '../../../context/editor/store/store';
import PipeEdge from './edges/PipeEdge';
import SignalEdge from './edges/SignalEdge';
import StatusStack from '../../common/statusStack';
import demoData from '../../../constants/demo';

export const nodeTypes = {
  pipe: Pipe,
  fermentationTank: FermentationTank,
  valve: Valve,
  source: Source,
};

export const edgeTypes = {
  pipe: PipeEdge,
  signal: SignalEdge,
};

function Canvas({ isDemo }: { isDemo: boolean }) {
  const {
    nodes,
    edges,
    onConnect,
    onEdgesChange,
    onNodesChange,
    setNodes,
    setEdges,
  } = useStore((state: RFState) => ({
    nodes: state.nodes,
    edges: state.edges,
    onConnect: state.onConnect,
    onEdgesChange: state.onEdgesChange,
    onNodesChange: state.onNodesChange,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
  }));

  const flow = useReactFlow();

  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const potentialFlow = localStorage.getItem('flow');
    if (potentialFlow) {
      const rfInstance = JSON.parse(potentialFlow);
      setNodes(rfInstance.nodes);
      setEdges(rfInstance.edges);
    }
  }, []);

  useEffect(() => {
    if (!isDemo) {
      const flowObj = flow.toObject();
      localStorage.setItem('flow', JSON.stringify(flowObj));
    } else if (nodes.length === 0 && edges.length === 0) {
      const demoString = JSON.stringify(demoData);
      localStorage.setItem('flow', demoString);
      flow.addEdges(demoData.edges);
      flow.addNodes(demoData.nodes);
    }
  }, [nodes, edges, flow]);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const type = event.dataTransfer?.getData('application/reactflow');
      const label = event.dataTransfer?.getData('label');
      const componentType = event.dataTransfer?.getData('componentType');
      const componentID = event.dataTransfer?.getData('id');

      // Check if the type of item being dropped is a 'new-node'
      if (type === 'new-node' && reactFlowWrapper.current) {
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();
        // Calculate the position of the node
        const position = flow.screenToFlowPosition({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        // Add your new node here
        const newNode = {
          id: componentID,
          type: componentType, // or any type you want
          position,
          data: { label, type: componentType, data: {}, handles: {} },
        };

        // Adds the node to the context state to be used elsewhere.
        setNodes([newNode]);
      }
    },
    [setNodes]
  );

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  };

  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className={styles.canvas}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onConnect={onConnect}
        onDrag={(e) => console.log('Canvas drag', e)}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
      >
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
      <Link
        to={
          nodes[nodes.length - 1]?.id
            ? `/dashboard/equipment/${nodes[nodes.length - 1]?.type}/${
                nodes[nodes.length - 1]?.id
              }`
            : '/'
        }
      >
        <section className={styles.dashboardMinimap}>
          <Minimap />
        </section>
      </Link>
      <StatusStack />
    </div>
  );
}

export default Canvas;
