import { v4 as uuid } from 'uuid';
import SocketTypes from './types/socketTypes';

const URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_URL!
    : 'ws://127.0.0.1:8080/echo';

class WebSocketClient {
  socket: WebSocket;

  _id: string = uuid();

  timeoutId?: NodeJS.Timeout;

  constructor() {
    this.socket = new WebSocket(`${URL}`);

    this.initializeListeners();
  }

  initializeListeners() {
    this.socket.addEventListener('open', () => {
      const connection = {
        connect: true,
      };
      this.emit(SocketTypes.CONNECT, connection);
      console.log('Connection opened.');
      clearTimeout(this.timeoutId);
    });

    this.socket.addEventListener('close', () => {
      console.log('Connection closed.');
      // this.timeoutId = setTimeout(() => {
      //   this.socket = new WebSocket(`${URL}/echo`);
      //   this.initializeListeners();
      // }, 5000); // Attempt to reconnect after 5 seconds
    });
  }

  disconnect() {
    this.socket.close();
  }

  async emit<DataType>(type: SocketTypes, data: DataType) {
    const jsonString = await this.jsonToArrayBuffer(type, data);
    this.socket.send(jsonString);
  }

  private jsonToArrayBuffer<DataType>(
    type: SocketTypes,
    json: DataType
  ): Promise<ArrayBuffer> {
    // Convert JSON object to string
    const jsonObj = {
      // eslint-disable-next-line no-underscore-dangle
      client: this._id,
      type,
      ...json,
    };
    const jsonString: string = JSON.stringify(jsonObj);

    // Create a Blob object
    const blob: Blob = new Blob([jsonString]);

    // Return a promise that resolves with an ArrayBuffer
    return blob.arrayBuffer();
  }
}

export default WebSocketClient;
