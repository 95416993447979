import { create } from 'zustand';
// eslint-disable-next-line import/no-extraneous-dependencies

export type SignalState = {
  // A dictionary of the signals and their settings
  signalDictionary: {
    // Signal Edge ID
    [key: string]: {};
  };

  // A dictionary of the active signal settings popups
  activeSignalSettings: {
    // Signal Edge ID
    [key: string]: {
      clientX: number;
      clientY: number;
      sourceID: string;
      targetID: string;
    };
  };

  setActiveSignalSettings: (options: SignalSettingsType) => void;
};

export interface SignalSettingsType {
  clientX: number;
  clientY: number;
  id: string;
  sourceID: string;
  targetID: string;
}

const useSignalStore = create<SignalState>((set, get) => ({
  activeSignalSettings: {},
  signalDictionary: {},
  setActiveSignalSettings(options) {
    const { id, clientX, clientY, sourceID, targetID } = options;
    const maybeExists = get().activeSignalSettings[id];

    if (maybeExists) {
      const newObj = { ...get().activeSignalSettings };
      delete newObj[id];

      set({
        activeSignalSettings: newObj,
      });
    } else {
      set({
        activeSignalSettings: {
          ...get().activeSignalSettings,
          [id]: {
            clientX,
            clientY,
            sourceID,
            targetID,
          },
        },
      });
    }
  },
}));

export default useSignalStore;
