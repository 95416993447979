import { DateTime } from 'luxon';
import {
  FermentationTankValue,
  PipeValue,
  SimulationStorage,
  TimeSeriesData,
  ValveValue,
} from './types/nodes';

function handleFermentationTankData(
  data: TimeSeriesData<FermentationTankValue>[],
  initialState: SimulationStorage,
  simID: string
) {
  const sortedDictionary = data.reduce(
    (
      acc: SimulationStorage,
      datum: TimeSeriesData<FermentationTankValue>
    ): SimulationStorage => {
      // Initialize the id in the accumulator if it doesn't exist
      if (!acc[simID].fermentationTanks[datum.id]) {
        acc[simID].fermentationTanks[datum.id] = [];
      }

      // Add the object sorted by time
      acc[simID].fermentationTanks[datum.id].push(datum);
      acc[simID].fermentationTanks[datum.id].sort(
        (
          a: TimeSeriesData<FermentationTankValue>,
          b: TimeSeriesData<FermentationTankValue>
        ) =>
          DateTime.fromISO(a.time).toMillis() -
          DateTime.fromISO(b.time).toMillis()
      );

      return acc;
    },
    initialState
  );

  localStorage.setItem('simulate', JSON.stringify(sortedDictionary));
}

export function handleValveData(
  data: TimeSeriesData<ValveValue>[],
  initialState: SimulationStorage,
  simID: string
) {
  const sortedDictionary = data.reduce(
    (
      acc: SimulationStorage,
      datum: TimeSeriesData<ValveValue>
    ): SimulationStorage => {
      // Initialize the id in the accumulator if it doesn't exist
      if (!acc[simID].valves[datum.id]) {
        acc[simID].valves[datum.id] = [];
      }

      // Add the object sorted by time
      acc[simID].valves[datum.id].push(datum);
      acc[simID].valves[datum.id].sort(
        (a: TimeSeriesData<ValveValue>, b: TimeSeriesData<ValveValue>) =>
          DateTime.fromISO(a.time).toMillis() -
          DateTime.fromISO(b.time).toMillis()
      );

      return acc;
    },
    initialState
  );

  localStorage.setItem('simulate', JSON.stringify(sortedDictionary));
}

export function handlePipeData(
  data: TimeSeriesData<PipeValue>[],
  initialState: SimulationStorage,
  simID: string
) {
  const sortedDictionary = data.reduce(
    (
      acc: SimulationStorage,
      datum: TimeSeriesData<PipeValue>
    ): SimulationStorage => {
      // Initialize the id in the accumulator if it doesn't exist
      if (!acc[simID].pipes[datum.id]) {
        acc[simID].pipes[datum.id] = [];
      }

      // Add the object sorted by time
      acc[simID].pipes[datum.id].push(datum);
      acc[simID].pipes[datum.id].sort(
        (a: TimeSeriesData<PipeValue>, b: TimeSeriesData<PipeValue>) =>
          DateTime.fromISO(a.time).toMillis() -
          DateTime.fromISO(b.time).toMillis()
      );

      return acc;
    },
    initialState
  );

  localStorage.setItem('simulate', JSON.stringify(sortedDictionary));
}

export default handleFermentationTankData;
