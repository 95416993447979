import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { Handle, Position, useReactFlow } from 'reactflow';
import EditableLabel from '../editableName';
import NodeBottom from '../nodeBottom';

import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import valveStyles from '../../../../../styles/editor/canvas/nodes/valve.module.scss';
import ValveParameters from './ValveParameters';
import useStore, { RFState } from '../../../../../context/editor/store/store';

interface EditableNodeProps {
  id: string;
  data: {
    label: string;
    handles: {
      sourceHandleRight: string | undefined;
      targetHandleLeft: string | undefined;
      sourceSignalHandleRight: string | undefined;
      targetSignalHandleLeft: string | undefined;
      targetSignalHandleTop: string | undefined;
      sourceSignalHandleBottom: string | undefined;
    };
    parameters: {
      [key: string]: any;
    };
    [key: string]: any;
  };
  selected: boolean;
  type: string;
}

function Valve({ id, data, selected, type }: EditableNodeProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [clickedDropdown, setClickedDropdown] = useState<boolean>(false);
  const [label, setNewLabel] = useState<string>(data.label);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isSignalShowing } = useStore((state: RFState) => ({
    isSignalShowing: state.showSignal,
  }));

  const { setNodes } = useReactFlow();

  const [sourceHandleRightID] = useState<string>(
    data.handles.sourceHandleRight ?? `valve_${v4()}`
  );
  const [targetHandleLeftID] = useState<string>(
    data.handles.targetHandleLeft ?? `valve_${v4()}`
  );
  const [sourceSignalHandleRightID] = useState<string>(
    data.handles.sourceSignalHandleRight ?? `valve_signal_${v4()}`
  );
  const [targetSignalHandleLeftID] = useState<string>(
    data.handles.targetSignalHandleLeft ?? `valve_signal_${v4()}`
  );
  const [targetSignalHandleTopID] = useState<string>(
    data.handles.targetSignalHandleTop ?? `valve_signal_${v4()}`
  );
  const [sourceSignalHandleBottomID] = useState<string>(
    data.handles.sourceSignalHandleBottom ?? `valve_signal_${v4()}`
  );

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const newNode = node;
        if (node.id === id) {
          newNode.data.handles.sourceHandleRight = sourceHandleRightID;
          newNode.data.handles.targetHandleLeft = targetHandleLeftID;
        }
        return newNode;
      })
    );
  }, [sourceHandleRightID, targetHandleLeftID]);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const newNode = node;
        if (node.id === id) {
          newNode.data.handles.sourceSignalHandleRight =
            sourceSignalHandleRightID;
          newNode.data.handles.targetSignalHandleLeft =
            targetSignalHandleLeftID;
          newNode.data.handles.sourceSignalHandleBottom =
            sourceSignalHandleBottomID;
          newNode.data.handles.targetSignalHandleTop = targetSignalHandleTopID;
        }
        return newNode;
      })
    );
  }, [
    sourceSignalHandleRightID,
    sourceSignalHandleBottomID,
    targetSignalHandleLeftID,
    targetSignalHandleTopID,
  ]);

  // Listen for "Enter" key to trigger edit mode
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && selected) {
        if (!isEditing) {
          event.preventDefault();
          setIsEditing(true);
          setClickedDropdown(true);
        } else {
          setIsEditing(false);
          setClickedDropdown(false);
        }
      }
    },
    [isEditing, selected]
  );

  const setLabel = (newLabel: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const newNode = node;
        if (node.id === id) {
          newNode.data.label = newLabel;
        }
        return newNode;
      })
    );
    setNewLabel(newLabel);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Focus on the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.containerWrapper}
          onDoubleClick={() => {
            window.location.href = `dashboard/equipment/${type}/${id}`;
          }}
        >
          <div className={styles.labelWrapper}>
            <div className={valveStyles.valveIcon} />
            {isEditing ? (
              <EditableLabel
                label={label}
                setLabel={setLabel}
                inputRef={inputRef}
              />
            ) : (
              <h3 className={styles.containerLabel}>{label}</h3>
            )}
          </div>
          <FontAwesomeIcon
            icon={faCaretDown}
            className={styles.containerIcon}
            size="lg"
            transform={{ rotate: clickedDropdown ? 180 : 0 }}
            onClick={() => setClickedDropdown(!clickedDropdown)}
          />
        </div>

        {clickedDropdown && (
          <section className={styles.dropdownSection}>
            <ValveParameters id={id} params={data.parameters} />
            <NodeBottom id={id} type={type} />
          </section>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable
        id={sourceHandleRightID}
        style={
          !isSignalShowing
            ? {}
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable
        id={targetHandleLeftID}
        style={
          !isSignalShowing
            ? {}
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable
        id={targetSignalHandleLeftID}
        style={
          isSignalShowing
            ? {
                background: 'red',
                width: '10px',
                height: '10px',
                border: '1px solid black',
              }
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable
        id={sourceSignalHandleRightID}
        style={
          isSignalShowing
            ? {
                background: 'red',
                width: '10px',
                height: '10px',
                border: '1px solid black',
              }
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
      <Handle
        type="target"
        position={Position.Top}
        isConnectable
        id={targetSignalHandleTopID}
        style={
          isSignalShowing
            ? {
                background: 'red',
                width: '10px',
                height: '10px',
                border: '1px solid black',
              }
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable
        id={sourceSignalHandleBottomID}
        style={
          isSignalShowing
            ? {
                background: 'red',
                width: '10px',
                height: '10px',
                border: '1px solid black',
              }
            : { visibility: 'hidden', pointerEvents: 'none' }
        }
      />
    </>
  );
}

export default Valve;
