export interface FluidParameters {
  [key: string]: any;
}

export interface FlattenedUnitParameters {
  [key: string]: {
    value: number;
    unit: string;
  };
}

export interface FlattenedEnumParameters {
  [key: string]: string;
}

export interface FlattenedBooleanParameters {
  [key: string]: boolean;
}

export interface FlattenedTimeParameters {
  [key: string]: {
    [key: string]: {
      [key: string]: number;
    };
  };
}

export const flattenUnitParameters = (
  e: React.ChangeEvent<HTMLFormElement>
): FlattenedUnitParameters => {
  const elements = Array.from(e.currentTarget.elements);
  const fieldsets = elements.filter((ele) => ele.tagName === 'FIELDSET');
  const parameters = fieldsets
    .map((fieldset): FlattenedUnitParameters | undefined => {
      const ID = fieldset.id;

      const valueElement = fieldset.querySelector('#value');
      if (!valueElement) return undefined;
      const { value } = valueElement as HTMLInputElement;

      const unitElement = fieldset.querySelector('#unit');
      if (!unitElement) return undefined;
      const unit = (unitElement as HTMLSelectElement).value;

      // If it's not a unit, don't add it.
      if (!value || !unit) return undefined;

      const convertedUnit = parseFloat(value) ?? 0.0;

      return { [ID]: { value: convertedUnit, unit } };
    })
    .filter(
      // Remove all of the undefined values. This is a pseudo-compactMap
      (n: FlattenedUnitParameters | undefined) => n
    ) as FlattenedUnitParameters[];
  if (parameters.length === 0) return {};
  const flattenedParameters = parameters.reduce((acc, obj) => ({
    ...acc,
    ...obj,
  }));
  return flattenedParameters;
};

export const flattenTimeParameters = (
  e: React.ChangeEvent<HTMLFormElement>
): FlattenedTimeParameters => {
  const elements = Array.from(e.currentTarget.elements);
  const fieldsets = elements.filter((ele) => ele.tagName === 'FIELDSET');
  const parameters = fieldsets
    .map((fieldset): FlattenedTimeParameters | undefined => {
      const ID = fieldset.id;

      const valueElement = fieldset.querySelector('#timeVal');
      if (!valueElement) return undefined;
      const { value } = valueElement as HTMLInputElement;

      const unitElement = fieldset.querySelector('#timeUnit');
      if (!unitElement) return undefined;
      const unit = (unitElement as HTMLSelectElement).value;

      // If it's not a unit, don't add it.
      if (!value || !unit) return undefined;

      const convertedUnit = parseFloat(value) ?? 0.0;

      return { [ID]: { [unit]: { _0: convertedUnit } } };
    })
    .filter(
      // Remove all of the undefined values. This is a pseudo-compactMap
      (n: FlattenedTimeParameters | undefined) => n
    ) as FlattenedTimeParameters[];
  if (parameters.length === 0) return {};
  const flattenedParameters = parameters.reduce((acc, obj) => ({
    ...acc,
    ...obj,
  }));
  return flattenedParameters;
};

export const flattenBooleanParameters = (
  e: React.ChangeEvent<HTMLFormElement>
): FlattenedBooleanParameters => {
  const elements = Array.from(e.currentTarget.elements);
  const fieldsets = elements.filter((ele) => ele.tagName === 'FIELDSET');
  const parameters = fieldsets
    .map((fieldset): FlattenedBooleanParameters | undefined => {
      const ID = fieldset.id;

      const valueElement = fieldset.querySelector('#boolVal');
      if (!valueElement) return undefined;
      const { value } = valueElement as HTMLSelectElement;

      // If it's not a value, don't add it.
      if (!value) return undefined;

      return { [ID]: value === 'true' };
    })
    .filter(
      // Remove all of the undefined values. This is a pseudo-compactMap
      (n: FlattenedBooleanParameters | undefined) => n
    ) as FlattenedBooleanParameters[];
  if (parameters.length === 0) return {};
  const flattenedParameters = parameters.reduce((acc, obj) => ({
    ...acc,
    ...obj,
  }));
  return flattenedParameters;
};

export const flattenEnumParameters = (
  e: React.ChangeEvent<HTMLFormElement>
): FlattenedEnumParameters => {
  const elements = Array.from(e.currentTarget.elements);
  const fieldsets = elements.filter((ele) => ele.tagName === 'FIELDSET');
  const parameters = fieldsets
    .map((fieldset): FlattenedEnumParameters | undefined => {
      const ID = fieldset.id;

      const valueElement = fieldset.querySelector('#enumVal');
      if (!valueElement) return undefined;
      const { value } = valueElement as HTMLSelectElement;

      // If it's not a value, don't add it.
      if (!value) return undefined;

      return { [ID]: value };
    })
    .filter(
      // Remove all of the undefined values. This is a pseudo-compactMap
      (n: FlattenedEnumParameters | undefined) => n
    ) as FlattenedEnumParameters[];
  if (parameters.length === 0) return {};
  const flattenedParameters = parameters.reduce((acc, obj) => ({
    ...acc,
    ...obj,
  }));
  return flattenedParameters;
};
