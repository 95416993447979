import React from 'react';
import useStore, { RFState } from '../../../context/editor/store/store';
import styles from '../../../styles/editor/buttonController/showSignaling.module.scss';
import ToggleSwitch, { SwitchSize } from '../../common/toggleSwitch';

function ShowSignaling() {
  const { isShowSignaling, toggleShowSignaling, nodes } = useStore(
    (state: RFState) => ({
      isShowSignaling: state.showSignal,
      toggleShowSignaling: state.toggleShowSignaling,
      nodes: state.nodes,
    })
  );
  return (
    <div className={styles.signalContainer}>
      {nodes.length > 1 ? (
        <>
          <ToggleSwitch
            rounded
            size={SwitchSize.SMALL}
            isToggled={isShowSignaling}
            onToggle={toggleShowSignaling}
          />
          <p className={styles.signalText}>Show Signals Only</p>
        </>
      ) : null}
    </div>
  );
}

export default ShowSignaling;
