import { DateTime } from 'luxon';
import React from 'react';

import styles from '../../../styles/common/dashboard/graphHeader.module.scss';

interface GraphHeaderProps {
  dates: {
    startDate: DateTime,
    endDate: DateTime,
  };
  title: string;
  icon: {
    url: string,
  };
}

const calculateStartDate = (date: DateTime): string => {
  if (!date.isValid) return 'Start Date';
  return date.toFormat('MMM d, h:mm a').replace('AM', 'am').replace('PM', 'pm');
};

const calculateEndDate = (date: DateTime): string => {
  if (!date.isValid) return 'End Date';
  return date.toFormat('MMM d, h:mm a').replace('AM', 'am').replace('PM', 'pm');
};

function GraphHeader({ dates, title, icon }: GraphHeaderProps) {
  return (
    <header className={styles.header}>
      <div className={styles.iconContainer}>
        <div
          className={styles.icon}
          style={{ backgroundImage: `url(${icon.url})` }}
        />
        <h1 className={styles.graphTitle}>{title}</h1>
      </div>
      <div className={styles.dateContainer}>
        <p className={styles.startDate}>
          {calculateStartDate(dates.startDate)}
        </p>
        <div className={styles.arrowIcon} />
        <p className={styles.endDate}>{calculateEndDate(dates.endDate)}</p>
      </div>
    </header>
  );
}

export default GraphHeader;
