import React from 'react';
import styles from '../../../../styles/editor/canvas/editableNode.module.scss';

interface EditableLabelProps {
  label: string;
  setLabel: (newLabel: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

function EditableLabel({ label, setLabel, inputRef }: EditableLabelProps) {
  return (
    <input
      ref={inputRef}
      value={label}
      className={styles.containerLabel}
      onChange={(e) => setLabel(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent form submission if inside a form
        }
      }}
    />
  );
}

export default EditableLabel;
