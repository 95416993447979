import {
  EquipmentMenuState,
  EquipmentReducerAction,
  EquipmentMenuActionTypes,
} from './stateType';

const equipmentReducer = (
  state: EquipmentMenuState,
  action: EquipmentReducerAction
) => {
  switch (action.type) {
    case EquipmentMenuActionTypes.TOGGLE:
      return { ...state, on: !state.on };
    default:
      throw new Error(
        `Unhandled action type in EquipmentReducer: ${action.type}`
      );
  }
};

export default equipmentReducer;
