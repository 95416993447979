/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  EquipmentMenuState,
  EquipmentReducerAction,
} from './equipMenu/stateType';

export interface EditorState {
  menuState: EquipmentMenuState;
  menuDispatch: (action: EquipmentReducerAction) => void;
}

export default React.createContext<EditorState>({
  menuState: { on: true },
  menuDispatch: (action: EquipmentReducerAction) => {},
});
