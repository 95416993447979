import {
  TimeSeriesReducerAction,
  TimeSeriesTypes,
} from '../../../api/types/nodes';
import WebSocketState from '../../../types/context/webSocket';

const timeSeriesReducer = (
  state: WebSocketState,
  action: TimeSeriesReducerAction
): WebSocketState => {
  switch (action.type) {
    case TimeSeriesTypes.FERMENTATIONTANK: {
      const currentFermentationTanks = state.timeSeries.fermentationTanks;
      currentFermentationTanks[action.payload.data.id].push(
        action.payload.data
      );
      return {
        ...state,
        timeSeries: {
          ...state.timeSeries,
          fermentationTanks: currentFermentationTanks,
        },
      };
    }
    default:
      return state;
  }
};

export default timeSeriesReducer;
