import React, { ChangeEventHandler } from 'react';
import TankParameterTypeSelector from '../../../../../common/nodes/tankParamSelectionType';
import UnitParameter from '../../../../../common/nodes/unitParameter';
import FLUIDROLE from '../../../../../../types/fluidTypes';

function MixtureParameters({
  setParamType,
  param,
  updateData,
  mixture,
}: {
  setParamType: React.Dispatch<React.SetStateAction<string>>;
  param: string;
  updateData: ChangeEventHandler<HTMLFormElement>;
  mixture: {
    fluids: {
      [key: string]: any;
      concentration: {
        value: string;
        unit: string;
      };
      fluidRole: FLUIDROLE;
    }[];
  };
}) {
  return (
    <form onChange={updateData}>
      <TankParameterTypeSelector setParamType={setParamType} param={param} />
      <UnitParameter
        title={{ label: 'Sucrose Concentration', id: FLUIDROLE.SUBSTRATE }}
        initialUnit={
          mixture?.fluids.filter(
            (fluid) => fluid.fluidRole === FLUIDROLE.SUBSTRATE
          )[0]?.concentration ?? {}
        }
        options={[
          {
            label: 'g/L',
            value: 'gramsPerLiter',
          },
        ]}
      />
      <UnitParameter
        title={{ label: 'Product Concentration', id: FLUIDROLE.PRODUCT }}
        initialUnit={
          mixture?.fluids.filter(
            (fluid) => fluid.fluidRole === FLUIDROLE.PRODUCT
          )[0]?.concentration ?? {}
        }
        options={[
          {
            label: 'g/L',
            value: 'gramsPerLiter',
          },
        ]}
      />
      <UnitParameter
        title={{ label: 'Biomass Concentration', id: FLUIDROLE.BIOMASS }}
        initialUnit={
          mixture?.fluids.filter(
            (fluid) => fluid.fluidRole === FLUIDROLE.BIOMASS
          )[0]?.concentration ?? {}
        }
        options={[
          {
            label: 'g/L',
            value: 'gramsPerLiter',
          },
        ]}
      />
    </form>
  );
}

export default MixtureParameters;
