import React from 'react';
import { Node } from 'reactflow';
import { v4 } from 'uuid';
import styles from '../../../styles/common/connections/signalSettings.module.scss';
import {
  Comparison,
  SignalValueType,
  TriggerValueBool,
  TriggerValueDouble,
  TriggerValueString,
  TriggerValueUnit,
  ControlProperty,
} from '../../../types/signalTypes';
import PossibleValues from './PossibleValues';
import { selectedComparisonType } from './utils/retrieveStaticProperties';

export enum ControllerCase {
  CONTROL = 'control',
  RESPONSE = 'response',
}

export enum InputType {
  SELECT = 'select',
  INPUT = 'input',
}

interface GroupedComparatorProps {
  node: Node | undefined;
  type: ControllerCase;
  controlInitialValues: {
    selectedProperty: ControlProperty | undefined;
    setSelectedProperty: React.Dispatch<
      React.SetStateAction<ControlProperty | undefined>
    >;
    allProperties: ControlProperty[];
    controlProp:
      | TriggerValueUnit
      | TriggerValueString
      | TriggerValueDouble
      | TriggerValueBool
      | undefined;
    setControlProp: React.Dispatch<
      React.SetStateAction<
        | TriggerValueUnit
        | TriggerValueString
        | TriggerValueDouble
        | TriggerValueBool
        | undefined
      >
    >;
    controlComparison: Comparison | undefined;
    setComparison: React.Dispatch<React.SetStateAction<Comparison | undefined>>;
  };
  responseInitialValues: {
    selectedProperty: ControlProperty | undefined;
    setSelectedProperty: React.Dispatch<
      React.SetStateAction<ControlProperty | undefined>
    >;
    allProperties: ControlProperty[];
    setValue:
      | TriggerValueBool
      | TriggerValueDouble
      | TriggerValueString
      | TriggerValueUnit
      | undefined;
    setSetValue: React.Dispatch<
      React.SetStateAction<
        | TriggerValueUnit
        | TriggerValueString
        | TriggerValueDouble
        | TriggerValueBool
        | undefined
      >
    >;
  };
}

function GroupedComparator({
  node,
  type,
  controlInitialValues,
  responseInitialValues,
}: GroupedComparatorProps) {
  const handleControlPropChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const prop = controlInitialValues.allProperties.find(
      (possibleProp) => possibleProp.value === e.currentTarget.value
    );
    controlInitialValues.setSelectedProperty(prop);
  };

  const handleResponsePropChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const prop = responseInitialValues.allProperties.find(
      (possibleProp) => possibleProp.value === e.currentTarget.value
    );
    responseInitialValues.setSelectedProperty(prop);
  };

  switch (type) {
    case ControllerCase.CONTROL:
      return (
        <section className={styles.controlContainer}>
          <h4 className={styles.controlTitle}>
            Control Node: {node?.data.label}
          </h4>
          <div className={styles.configurables}>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Control Property
              </label>
              <select
                name=""
                id=""
                onChange={handleControlPropChange}
                value={controlInitialValues.selectedProperty?.value}
              >
                {controlInitialValues.allProperties.map((prop) => (
                  <option key={`${prop.value}_${v4()}`} value={prop.value}>
                    {prop.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Comparison
              </label>
              <select
                name=""
                id="property"
                onChange={(e) =>
                  controlInitialValues.setComparison(
                    e.currentTarget.value as Comparison
                  )
                }
                defaultValue={controlInitialValues.controlComparison}
              >
                {selectedComparisonType(
                  controlInitialValues.selectedProperty?.type ??
                    SignalValueType.BOOLEAN
                ).map((comparison) => (
                  <option
                    key={`${comparison.value}_${v4()}`}
                    value={comparison.value}
                  >
                    {comparison.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Property Value
              </label>
              <PossibleValues
                options={controlInitialValues.selectedProperty?.options}
                initialVal={controlInitialValues.controlProp}
                setControlPropValue={controlInitialValues.setControlProp}
              />
            </div>
          </div>
        </section>
      );
    case ControllerCase.RESPONSE:
      return (
        <section className={styles.controlContainer}>
          <h4 className={styles.controlTitle}>
            Response Node: {node?.data.label}
          </h4>
          <div className={styles.configurables}>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Responding Property
              </label>
              <select
                name=""
                id=""
                onChange={handleResponsePropChange}
                value={responseInitialValues.selectedProperty?.value}
              >
                {responseInitialValues.allProperties.map((prop) => (
                  <option key={`${prop.value}_${v4()}`} value={prop.value}>
                    {prop.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Comparison
              </label>
              <select name="" id="">
                <option value="equalTo">=</option>
              </select>
            </div>
            <div className={styles.property}>
              <label className={styles.label} htmlFor="property">
                Responding Value
              </label>
              <PossibleValues
                options={responseInitialValues.selectedProperty?.options}
                initialVal={responseInitialValues.setValue}
                setControlPropValue={responseInitialValues.setSetValue}
              />
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
}

export default GroupedComparator;
