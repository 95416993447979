import {
  ValueHTMLTypes,
  SignalValueType,
  TriggerValueType,
} from '../../types/signalTypes';

export const valveSetProperties = [
  {
    setProperty: 'setPointOpenness',
    label: 'Set Point Openness',
    type: SignalValueType.UNIT,
    options: {
      valueType: TriggerValueType.UNIT,
      type: ValueHTMLTypes.INPUT,
      choices: [
        {
          value: 'percentage',
          label: '%',
        },
      ],
    },
  },
];

export const valveTriggerProperties = [
  {
    triggerProperty: 'openness',
    label: 'Openness',
    type: SignalValueType.UNIT,
    options: {
      valueType: TriggerValueType.UNIT,
      type: ValueHTMLTypes.INPUT,
      choices: [
        {
          value: 'percentage',
          label: '%',
        },
      ],
    },
  },
];
