import React from 'react';
import styles from '../../../styles/editor/menu.module.scss';
import Equipment from './equipment';
import EQUIPMENTTYPE from '../../../types/equipmentTypes';
import ShowSignaling from '../buttonController/showSignaling';
import useEquipmentStore, {
  EquipmentState,
} from '../../../context/editor/store/equipmentStore';

function EquipmentMenu() {
  const { menuShowing } = useEquipmentStore((state: EquipmentState) => ({
    menuShowing: state.menuShowing,
  }));

  return (
    <aside
      className={
        !menuShowing ? `${styles.menu}` : `${styles.menu} ${styles.open}`
      }
    >
      <Equipment name="Valve" type={EQUIPMENTTYPE.Valve} />
      <Equipment
        name="Fermentation Tank"
        type={EQUIPMENTTYPE.FermentationTank}
      />
      <Equipment name="Source" type={EQUIPMENTTYPE.Source} />
      {/* Is this where we want this component living? */}
      <ShowSignaling />
    </aside>
  );
}

export default EquipmentMenu;
