import React, { ChangeEventHandler, useState } from 'react';
import EnumParameter from '../../../../../common/nodes/enumParameter';
import UnitParameter from '../../../../../common/nodes/unitParameter';
import styles from '../../../../../../styles/editor/canvas/editableNode.module.scss';
import BooleanParameter from '../../../../../common/nodes/booleanParameter';
import TankParameterTypeSelector from '../../../../../common/nodes/tankParamSelectionType';

function GenericParameters({
  setParamType,
  param,
  updateData,
  generics,
}: {
  setParamType: React.Dispatch<React.SetStateAction<string>>,
  param: String,
  updateData: ChangeEventHandler<HTMLFormElement>,
  generics: {
    [key: string]: any,
  },
}) {
  const [batchTime, setBatchTime] = useState(generics?.batchTime ?? {});
  const [downTime, setDownTime] = useState(generics?.downTime ?? {});

  return (
    <form onChange={updateData}>
      <TankParameterTypeSelector setParamType={setParamType} param={param} />
      <div className={styles.parameter}>
        <p className={styles.paramText}>Batch Time</p>
        <div className={styles.inputWrapper}>
          <fieldset id="batchTime">
            <input
              type="number"
              id="timeVal"
              // eslint-disable-next-line no-underscore-dangle
              value={batchTime[Object.keys(batchTime)[0]]?._0 ?? ''}
              className={styles.input}
              onChange={(e) => {
                const units = Object.keys(batchTime);
                const unit = units[0];
                setBatchTime({
                  [unit]: { _0: e.currentTarget.value },
                });
              }}
            />
            <select
              name="units"
              id="timeUnit"
              className={styles.select}
              defaultValue={Object.keys(batchTime)[0]}
              onChange={(e) => {
                const units = Object.keys(batchTime);
                const unit = units[0];
                // eslint-disable-next-line no-underscore-dangle
                const value = batchTime[unit]?._0;
                setBatchTime({ [e.currentTarget.value]: { _0: value } });
              }}
            >
              <option value="seconds">seconds</option>
              <option value="hours">hours</option>
              <option value="days">days</option>
              <option value="weeks">weeks</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div className={styles.parameter}>
        <p className={styles.paramText}>Downtime</p>
        <div className={styles.inputWrapper}>
          <fieldset id="downTime">
            <input
              type="number"
              id="timeVal"
              className={styles.input}
              // eslint-disable-next-line no-underscore-dangle
              value={downTime[Object.keys(downTime)[0]]?._0 ?? ''}
              onChange={(e) => {
                const units = Object.keys(downTime);
                const unit = units[0];
                setDownTime({
                  [unit]: { _0: e.currentTarget.value },
                });
              }}
            />
            <select
              name="units"
              id="timeUnit"
              className={styles.select}
              defaultValue={Object.keys(downTime)[0]}
              onChange={(e) => {
                const units = Object.keys(downTime);
                const unit = units[0];
                // eslint-disable-next-line no-underscore-dangle
                const value = downTime[unit]?._0;
                setDownTime({ [e.currentTarget.value]: { _0: value } });
              }}
            >
              <option value="seconds">seconds</option>
              <option value="hours">hours</option>
              <option value="days">days</option>
              <option value="weeks">weeks</option>
            </select>
          </fieldset>
        </div>
      </div>
      <UnitParameter
        title={{ label: 'Tank Size', id: 'tankSize' }}
        initialUnit={generics?.tankSize ?? {}}
        options={[
          { label: 'liters', value: 'liters' },
          { label: 'm^3', value: 'cubicMeters' },
          { label: 'ft^3', value: 'cubicFeet' },
          { label: 'gallons', value: 'gallons' },
        ]}
      />
      <EnumParameter
        title={{ label: 'Tank Type', id: 'tankType' }}
        initialUnit="batch"
        options={[
          { label: 'Batch', value: 'batch' },
          { label: 'Continuous', value: 'continuous' },
        ]}
      />
      <UnitParameter
        title={{ label: 'Initial Volume', id: 'currentVolume' }}
        initialUnit={generics?.currentVolume ?? {}}
        options={[
          { label: 'liters', value: 'liters' },
          { label: 'm^3', value: 'cubicMeters' },
          { label: 'ft^3', value: 'cubicFeet' },
          { label: 'gallons', value: 'gallons' },
        ]}
      />
      <UnitParameter
        title={{ label: 'Inlet Pressure', id: 'inletPressure' }}
        initialUnit={generics?.inletPressure ?? {}}
        options={[
          { label: 'psi', value: 'psi' },
          { label: 'bar', value: 'bar' },
          { label: 'atm', value: 'atm' },
        ]}
      />
      <UnitParameter
        title={{ label: 'Inlet Temperature', id: 'inletTemperature' }}
        initialUnit={generics?.inletTemperature ?? {}}
        options={[
          { label: 'kelvin', value: 'kelvin' },
          { label: 'celsius', value: 'celsius' },
          { label: 'fahrenheit', value: 'fahrenheit' },
        ]}
      />
      <BooleanParameter
        title={{ label: 'Reset Between Batches', id: 'resetBetweenBatches' }}
        initialUnit={generics?.resetBetweenBatches ?? {}}
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
      />
    </form>
  );
}

export default GenericParameters;
