import React, { ChangeEventHandler } from 'react';
import UnitParameter from '../../../../../common/nodes/unitParameter';
import TankParameterTypeSelector from '../../../../../common/nodes/tankParamSelectionType';

function KineticParameters({
  setParamType,
  param,
  updateData,
  kinetics,
}: {
  setParamType: React.Dispatch<React.SetStateAction<string>>,
  param: string,
  updateData: ChangeEventHandler<HTMLFormElement>,
  kinetics: {
    all: {
      _0: {
        [key: string]: any,
      },
    },
  },
}) {
  return (
    <form onChange={updateData}>
      <TankParameterTypeSelector setParamType={setParamType} param={param} />
      <UnitParameter
        title={{ label: 'µmax', id: 'maximumSpecificGrowthRate' }}
        // eslint-disable-next-line no-underscore-dangle
        initialUnit={kinetics?.all?._0?.maximumSpecificGrowthRate ?? {}}
        options={[
          { label: '1/seconds', value: 'inverseSeconds' },
          { label: '1/hours', value: 'inverseHours' },
          { label: '1/days', value: 'inverseDays' },
          { label: '1/weeks', value: 'inverseWeeks' },
        ]}
      />
      <UnitParameter
        title={{ label: 'k2', id: 'citricAcidGrowthProductionCoefficient' }}
        initialUnit={
          // eslint-disable-next-line no-underscore-dangle
          kinetics?.all?._0?.citricAcidGrowthProductionCoefficient ?? {}
        }
        options={[{ label: 'unitless', value: 'unitless' }]}
        style={{ display: 'none' }}
      />
      <UnitParameter
        title={{ label: 'k3', id: 'citricAcidBiomassProductionCoefficient' }}
        initialUnit={
          // eslint-disable-next-line no-underscore-dangle
          kinetics?.all?._0?.citricAcidBiomassProductionCoefficient ?? {}
        }
        options={[
          { label: '1/seconds', value: 'inverseSeconds' },
          { label: '1/hours', value: 'inverseHours' },
          { label: '1/days', value: 'inverseDays' },
          { label: '1/weeks', value: 'inverseWeeks' },
        ]}
      />
      <UnitParameter
        title={{ label: 'Ks', id: 'halfVmaxProduction' }}
        // eslint-disable-next-line no-underscore-dangle
        initialUnit={kinetics?.all?._0?.halfVmaxProduction ?? {}}
        options={[{ label: 'unitless', value: 'unitless' }]}
        style={{ display: 'none' }}
      />
      <UnitParameter
        title={{ label: 'Kx', id: 'halfVmaxGrowth' }}
        // eslint-disable-next-line no-underscore-dangle
        initialUnit={kinetics?.all?._0?.halfVmaxGrowth ?? {}}
        options={[{ label: 'unitless', value: 'unitless' }]}
        style={{ display: 'none' }}
      />
      <UnitParameter
        title={{ label: 'Ysx', id: 'reciprocalYieldCoeffBiomass' }}
        // eslint-disable-next-line no-underscore-dangle
        initialUnit={kinetics?.all?._0?.reciprocalYieldCoeffBiomass ?? {}}
        options={[{ label: 'unitless', value: 'unitless' }]}
        style={{ display: 'none' }}
      />
      <UnitParameter
        title={{ label: 'Ysp', id: 'reciprocalYieldCoeffProduct' }}
        // eslint-disable-next-line no-underscore-dangle
        initialUnit={kinetics?.all?._0?.reciprocalYieldCoeffProduct ?? {}}
        options={[{ label: 'unitless', value: 'unitless' }]}
        style={{ display: 'none' }}
      />
    </form>
  );
}

export default KineticParameters;
