import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCircleCheck,
  faExclamation,
  faExclamationTriangle,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import styles from '../../styles/common/status.module.scss';
import { StatusType } from '../../api/types/simulationTypes';
import useSimulationStore, {
  SimulationState,
} from '../../context/editor/store/simulationStore';

interface StatusProps {
  title: string;
  message: string;
  learnMoreLink: string;
  status: StatusType;
  offset:
    | {
        right: string;
        bottom: string;
        zIndex: number;
      }
    | undefined;
  id: string;
}

function Status({
  title,
  message,
  learnMoreLink,
  status,
  offset,
  id,
}: StatusProps) {
  const { bringToFront, removeStatus } = useSimulationStore(
    (state: SimulationState) => ({
      bringToFront: state.bringStatusToFront,
      removeStatus: state.removeStatus,
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [borderColor] = useState(`var(--${status}Border)`);
  const [shadeColor] = useState(`var(--${status}Shade)`);
  const [darkAccent] = useState(`var(--${status}DarkAccent)`);
  const [lightAccent] = useState(`var(--${status}LightAccent)`);

  const [iconDefinition] = useState<IconDefinition>(() => {
    switch (status) {
      case StatusType.warning:
        return faExclamationTriangle;
      case StatusType.success:
        return faCircleCheck;
      case StatusType.error:
        return faExclamation;
      default:
        return faCircleCheck;
    }
  });

  return (
    <div
      className={styles.statusCard}
      tabIndex={0}
      role="button"
      style={
        offset
          ? {
              border: `1px solid ${darkAccent}`,
              background: shadeColor,
              zIndex: offset.zIndex,
              right: offset.right,
              bottom: offset.bottom,
              position: 'absolute',
            }
          : { border: `1px solid ${darkAccent}`, background: shadeColor }
      }
      onClick={(e) => {
        e.stopPropagation();
        console.log('Clicked');
        bringToFront(id);
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
        bringToFront(id);
      }}
    >
      <FontAwesomeIcon
        icon={iconDefinition}
        className={styles.icon}
        style={{ color: darkAccent }}
      />
      <div className={styles.messageContainer}>
        <h4 className={styles.status} style={{ color: darkAccent }}>
          {title}
        </h4>
        <p className={styles.message} style={{ color: lightAccent }}>
          {message}
        </p>
        <Link to={learnMoreLink}>
          <button
            type="button"
            className={styles.learnMore}
            style={{ color: darkAccent }}
          >
            Learn more
          </button>
        </Link>
      </div>
      <button type="button" aria-label="Remove status">
        <FontAwesomeIcon
          icon={faX}
          className={styles.exit}
          style={{ color: darkAccent }}
          onClick={(e) => {
            e.stopPropagation();
            removeStatus(id);
          }}
        />
      </button>
    </div>
  );
}

export default Status;
