import { Unit } from '../api/types/nodes';
import EQUIPMENTTYPE from './equipmentTypes';

export enum Comparison {
  EQUALTO = 'equalTo',
  GREATERTHANOREQUALTO = 'greaterThanOrEqualTo',
  GREATERTHAN = 'greaterThan',
  LESSTHANOREQUALTO = 'lessThanOrEqualTo',
  LESSTHAN = 'lessThan',
}

export enum ValueHTMLTypes {
  SELECT = 'select',
  INPUT = 'input',
}

export enum TriggerValueType {
  UNIT = 'unit',
  STRING = 'string',
  DOUBLE = 'double',
  BOOL = 'bool',
}

export interface RawControlSignal {
  rawControlSource: RawControlSource | undefined;
  rawControlTarget: RawControlTarget | undefined;
}

export interface RawControlSource {
  id: string;
  type: EQUIPMENTTYPE;
  triggerProperty: string;
  triggerValue:
    | TriggerValueUnit
    | TriggerValueString
    | TriggerValueDouble
    | TriggerValueBool;
  comparison: Comparison;
}

export interface RawControlTarget {
  id: string;
  type: EQUIPMENTTYPE;
  setProperty: string;
  setValue: TriggerValueUnit;
}

export interface TriggerValueUnit extends Unit {}

export interface TriggerValueString {
  string: {
    _0: string;
  };
}

export interface TriggerValueDouble {
  double: {
    _0: number;
  };
}

export interface TriggerValueBool {
  bool: {
    _0: boolean;
  };
}

export enum SignalValueType {
  BOOLEAN = 'boolean',
  UNIT = 'unit',
  STRING = 'string',
  DOUBLE = 'double',
}

export interface ControlProperty {
  value: string;
  label: string;
  type: SignalValueType;
  options: {
    type: ValueHTMLTypes;
    valueType: TriggerValueType;
    choices: { value: string; label: string }[];
  };
}
