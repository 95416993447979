import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position } from 'reactflow';
import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import NodeBottom from '../nodeBottom';
import PipeParameters from './PipeParams';
import EditableLabel from '../editableName';

import pipeStyles from '../../../../../styles/editor/canvas/nodes/pipe.module.scss';

interface EditableNodeProps {
  id: string;
  data: {
    label: string;
    parameters: {
      [key: string]: any;
    };
  };
  selected: boolean;
  type: string;
}

function Pipe({
  id, data, selected, type,
}: EditableNodeProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [clickedDropdown, setClickedDropdown] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(data.label);
  const inputRef = useRef<HTMLInputElement>(null);

  // Listen for "Enter" key to trigger edit mode
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && selected) {
        if (!isEditing) {
          event.preventDefault();
          setIsEditing(true);
        } else {
          setIsEditing(false);
        }
      }
    },
    [isEditing, selected],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Focus on the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [isEditing]);
  return (
    <div className={styles.container}>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Left} />
      <Handle type="target" position={Position.Right} />
      <div className={styles.containerWrapper}>
        <div className={styles.labelWrapper}>
          <div className={pipeStyles.pipeIcon} />
          {isEditing ? (
            <EditableLabel
              label={label}
              setLabel={setLabel}
              inputRef={inputRef}
            />
          ) : (
            <h3 className={styles.containerLabel}>{label}</h3>
          )}
        </div>
        <FontAwesomeIcon
          icon={faCaretDown}
          className={styles.containerIcon}
          size="lg"
          transform={{ rotate: clickedDropdown ? 180 : 0 }}
          onClick={() => setClickedDropdown(!clickedDropdown)}
        />
      </div>

      {clickedDropdown && (
        <section className={styles.dropdownSection}>
          <PipeParameters id={id} params={data.parameters} />
          <NodeBottom id={id} type={type} />
        </section>
      )}
    </div>
  );
}

export default Pipe;
