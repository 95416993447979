import React, { useReducer, useMemo } from 'react';
import EditorContext, { EditorState as es } from './editor-context';
import equipmentReducer from './equipMenu/equipReducers';

// eslint-disable-next-line react/prop-types
function EditorState({ children }) {
  const [menuState, menuDispatch] = useReducer(equipmentReducer, { on: true });

  const state: es = useMemo(
    () => ({
      menuState,
      menuDispatch,
    }),
    [menuState, menuDispatch]
  );

  return (
    <EditorContext.Provider value={state}>{children}</EditorContext.Provider>
  );
}

export default EditorState;
