import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position, useReactFlow } from 'reactflow';
import { v4 } from 'uuid';
import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import NodeBottom from '../nodeBottom';
import SourceParameters from './SourceParameters';
import EditableLabel from '../editableName';

import sourceStyles from '../../../../../styles/editor/canvas/nodes/source.module.scss';

interface EditableNodeProps {
  id: string;
  data: {
    label: string;
    sourceHandleTop: string | undefined;
    sourceHandleRight: string | undefined;
    sourceHandleBottom: string | undefined;
    sourceHandleLeft: string | undefined;
    parameters: { [key: string]: any };
    [key: string]: any;
  };
  selected: boolean;
  type: string;
}

function Source({ id, data, selected, type }: EditableNodeProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [clickedDropdown, setClickedDropdown] = useState<boolean>(false);
  const [label, setNewLabel] = useState<string>(data.label);
  const inputRef = useRef<HTMLInputElement>(null);

  const { setNodes } = useReactFlow();

  const [sourceHandleTopID] = useState<string>(
    data.sourceHandleTop ?? `valve_${v4()}`
  );
  const [sourceHandleRightID] = useState<string>(
    data.sourceHandleRight ?? `valve_${v4()}`
  );
  const [sourceHandleBottomID] = useState<string>(
    data.sourceHandleBottom ?? `valve_${v4()}`
  );
  const [sourceHandleLeftID] = useState<string>(
    data.sourceHandleLeft ?? `valve_${v4()}`
  );

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const newNode = node;
        if (node.id === id) {
          newNode.data.sourceHandleRight = sourceHandleRightID;
          newNode.data.sourceHandleLeft = sourceHandleLeftID;
          newNode.data.sourceHandleBottom = sourceHandleBottomID;
          newNode.data.sourceHandleTop = sourceHandleTopID;
        }
        return newNode;
      })
    );
  }, [
    sourceHandleRightID,
    sourceHandleLeftID,
    sourceHandleTopID,
    sourceHandleBottomID,
  ]);

  // Listen for "Enter" key to trigger edit mode
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && selected) {
        if (!isEditing) {
          event.preventDefault();
          setIsEditing(true);
          setClickedDropdown(true);
        } else {
          setIsEditing(false);
          setClickedDropdown(false);
        }
      }
    },
    [isEditing, selected]
  );

  const setLabel = (newLabel: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const newNode = node;
        if (node.id === id) {
          newNode.data.label = newLabel;
        }
        return newNode;
      })
    );
    setNewLabel(newLabel);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Focus on the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [isEditing]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          <div className={styles.labelWrapper}>
            <div className={sourceStyles.sourceIcon} />
            {isEditing ? (
              <EditableLabel
                label={label}
                setLabel={setLabel}
                inputRef={inputRef}
              />
            ) : (
              <h3 className={styles.containerLabel}>{label}</h3>
            )}
          </div>
          <FontAwesomeIcon
            icon={faCaretDown}
            className={styles.containerIcon}
            size="lg"
            transform={{ rotate: clickedDropdown ? 180 : 0 }}
            onClick={() => setClickedDropdown(!clickedDropdown)}
          />
        </div>

        {clickedDropdown && (
          <section className={styles.dropdownSection}>
            <SourceParameters id={id} params={data.parameters} />
            <NodeBottom id={id} type={type} />
          </section>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable
        id={sourceHandleBottomID}
      />
      <Handle
        type="source"
        position={Position.Top}
        isConnectable
        id={sourceHandleTopID}
      />
      <Handle
        type="source"
        position={Position.Left}
        isConnectable
        id={sourceHandleLeftID}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable
        id={sourceHandleRightID}
      />
    </>
  );
}

export default Source;
