import React from 'react';
import useSimulationStore, {
  SimulationState,
} from '../../context/editor/store/simulationStore';
import styles from '../../styles/common/statusStack.module.scss';
import Status from './status';

function StatusStack() {
  const { statuses } = useSimulationStore((state: SimulationState) => ({
    statuses: state.statuses,
  }));

  return (
    <div className={styles.statusStack}>
      {statuses.map((status, index) => (
        <Status
          key={status.id}
          title={status.title}
          message={status.message ?? ''}
          status={status.status}
          learnMoreLink="/"
          offset={{
            zIndex: statuses.length - index,
            right: `${index * 10}px`,
            bottom: `${index * 10}px`,
          }}
          id={status.id}
        />
      ))}
    </div>
  );
}

export default StatusStack;
