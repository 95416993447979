// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand';
import { DateTime } from 'luxon';
import DateParams from '../../../types/simulationParameters';
import { Unit } from '../../../api/types/nodes';
import { SimulationStatus } from '../../../api/types/simulationTypes';

export type SimulationState = {
  simulating: boolean;
  dateParams: DateParams;
  // We need to capture what the setting was
  endDateUnit: string;
  endDateVal: number;

  statuses: SimulationStatus[];

  toggleSimulating: () => void;
  setSimulating: (as: boolean) => void;
  setEndDate: (val: Unit) => void;
  setTimeStep: (val: Unit) => void;

  removeStatus: (id: string) => void;
  addStatus: (status: SimulationStatus) => void;
  bringStatusToFront: (id: string) => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useSimulationStore = create<SimulationState>((set, get) => ({
  simulating: false,
  endDateUnit: 'days',
  endDateVal: 9,
  dateParams: {
    endDate: DateTime.now().plus({ days: 9 }).toISO(),
    timeStep: {
      minutes: {
        _0: 10,
      },
    },
  },

  statuses: [
    // {
    //   status: StatusType.success,
    //   message: 'Simulation completed successfully.',
    //   title: 'Successful Simulation',
    //   id: v4(),
    // },
    // {
    //   status: StatusType.warning,
    //   message: 'Simulation completed successfully.',
    //   title: 'Successful Simulation',
    //   id: v4(),
    // },
    // {
    //   status: StatusType.error,
    //   message: 'Simulation completed successfully.',
    //   title: 'Successful Simulation',
    //   id: v4(),
    // },
  ],

  setTimeStep(val) {
    console.log(val);
    set({
      dateParams: {
        ...get().dateParams,
        timeStep: {
          [val.unit]: {
            _0: val.value,
          },
        },
      },
    });
  },
  setEndDate(val) {
    let newDate: string;
    switch (val.unit) {
      case 'minutes': {
        newDate = DateTime.now().plus({ minutes: val.value }).toISO();
        break;
      }
      case 'hours': {
        newDate = DateTime.now().plus({ hours: val.value }).toISO();
        break;
      }
      case 'days': {
        newDate = DateTime.now().plus({ days: val.value }).toISO();
        break;
      }
      case 'seconds': {
        newDate = DateTime.now().plus({ seconds: val.value }).toISO();
        break;
      }
      default:
        return;
    }
    set({
      endDateVal: val.value,
      endDateUnit: val.unit,
      dateParams: {
        ...get().dateParams,
        endDate: newDate,
      },
    });
  },
  toggleSimulating: () => {
    set({
      simulating: !get().simulating,
    });
  },
  setSimulating: (as: boolean) => {
    set({
      simulating: as,
    });
  },

  addStatus(status) {
    const currentStatuses = [...get().statuses];
    currentStatuses.unshift(status);
    set({
      statuses: currentStatuses,
    });
  },

  removeStatus(id) {
    set({
      statuses: get().statuses.filter((status) => status.id !== id),
    });
  },

  bringStatusToFront(id) {
    const statusIdx = get().statuses.findIndex(
      (oldStatus) => oldStatus.id === id
    );
    const status = get().statuses[statusIdx];
    const newStatuses = [...get().statuses];
    newStatuses.splice(statusIdx, 1);
    newStatuses.unshift(status);
    set({
      statuses: newStatuses,
    });
  },
}));

export default useSimulationStore;
