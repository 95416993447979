import React from 'react';
import { v4 } from 'uuid';
import styles from '../../../styles/editor/menu.module.scss';
import EQUIPMENTTYPE from '../../../types/equipmentTypes';

const urlBank: { [key: EQUIPMENTTYPE]: string } = {
  pipe: 'https://untanglify-images.s3.amazonaws.com/pipeIcon.svg',
  valve: 'https://untanglify-images.s3.amazonaws.com/valveIcon.svg',
  fermentationTank: 'https://untanglify-images.s3.amazonaws.com/tank.svg',
  source: 'https://untanglify-images.s3.amazonaws.com/sourceIcon.svg',
};

function Equipment({ name, type }: { name: String, type: EQUIPMENTTYPE }) {
  const dragEvent = (event) => {
    event.dataTransfer.setData('application/reactflow', 'new-node');
    event.dataTransfer.setData('label', name);
    event.dataTransfer.setData('componentType', type);
    event.dataTransfer.setDragImage(event.target, 20, 20);
    event.dataTransfer.setData('id', Date.now().toString());
  };

  return (
    <div
      className={styles.equipment}
      draggable
      onDragStart={(e) => dragEvent(e)}
    >
      <div className={styles.draggableEdge}>
        {Array.apply(0, Array(10)).map(() => (
          <div className={styles.gridItem} key={v4()} />
        ))}
      </div>
      <div
        className={styles.icon}
        style={{ backgroundImage: `url(${urlBank[type]})` }}
      />
      <p className={styles.equipmentName}>{name}</p>
    </div>
  );
}

export default Equipment;
