import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Chart as ChartJS,
  Colors,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(Colors, CategoryScale, LinearScale, PointElement, LineElement);

const demoData = [
  { year: 2010, count: 10 },
  { year: 2011, count: 20 },
  { year: 2012, count: 15 },
  { year: 2013, count: 25 },
  { year: 2014, count: 22 },
  { year: 2015, count: 30 },
  { year: 2016, count: 28 },
];

const data: ChartData<'line', number[], number> = {
  labels: demoData.map((row) => row.year),
  datasets: [
    {
      data: demoData.map((row) => row.count),
    },
  ],
};

const options: ChartOptions<'line'> = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      title: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

function Minimap() {
  return <Line data={data} options={options} />;
}

export default Minimap;
