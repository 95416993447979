import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Node } from 'reactflow';
import styles from '../../../styles/common/connections/pipeSettings.module.scss';
import useStore, { RFState } from '../../../context/editor/store/store';
import usePipeStore, {
  PipeState,
} from '../../../context/editor/store/pipeStore';
import PipeParameters from '../../editor/canvas/nodes/Pipe/PipeParams';

function PipeSettings({
  id,
  params,
  clientX,
  clientY,
  sourceID,
  targetID,
}: {
  id: string;
  params: { [key: string]: any };
  clientX: number;
  clientY: number;
  sourceID: string;
  targetID: string;
}) {
  const { nodes } = useStore((state: RFState) => ({
    nodes: state.nodes,
  }));

  const { setActivePipeSettings } = usePipeStore((state: PipeState) => ({
    setActivePipeSettings: state.setActivePipeSettings,
  }));

  const [source, setSource] = useState<Node>();
  const [target, setTarget] = useState<Node>();

  useEffect(() => {
    const sourceNode = nodes.find((node) => node.id === sourceID);
    const targetNode = nodes.find((node) => node.id === targetID);

    setSource(sourceNode);
    setTarget(targetNode);
  }, [nodes]);

  const handleExitClick = () => {
    setActivePipeSettings({ id, params, sourceID, targetID, clientX, clientY });
  };

  return (
    <div
      className={styles.connectionSettingsContainer}
      style={{ left: clientX, top: clientY }}
    >
      <div className={styles.titleBox}>
        <h3 className={styles.title}>
          Pipe from {source?.data?.label ?? 'Source'} to{' '}
          {target?.data?.label ?? 'Target'}{' '}
        </h3>
        <FontAwesomeIcon
          icon={faX}
          className={styles.icon}
          onClick={handleExitClick}
        />
      </div>
      <PipeParameters id={id} params={params} />
    </div>
  );
}

export default PipeSettings;
