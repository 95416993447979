import React, { ChangeEventHandler } from 'react';
import { useReactFlow } from 'reactflow';
import styles from '../../../../../styles/common/connections/pipeSettings.module.scss';
import PipeUnitParameter from '../../../../common/connections/PipeUnitParameter';
import { flattenUnitParameters } from '../Utils/flattenParameters';

function PipeParameters({
  id,
  params,
}: {
  id: string;
  params: { [key: string]: any };
}) {
  const { setEdges } = useReactFlow();

  const updateData: ChangeEventHandler<HTMLFormElement> = (e) => {
    const flattenedParameters = flattenUnitParameters(e);

    setEdges((edges) =>
      edges.map((ed) => {
        if (ed.id === id) {
          return {
            ...ed,
            data: {
              ...ed.data,
              parameters: {
                ...flattenedParameters,
              },
            },
          };
        }

        return ed;
      })
    );
  };

  return (
    <section className={styles.middleSection}>
      <form action="" onChange={updateData}>
        <PipeUnitParameter
          title={{ label: 'Length', id: 'length' }}
          initialUnit={params?.length ?? {}}
          options={[
            { label: 'inches', value: 'inches' },
            { label: 'meters', value: 'meters' },
            { label: 'feet', value: 'feet' },
            { label: 'kilometers', value: 'kilometers' },
          ]}
        />
        <PipeUnitParameter
          title={{ label: 'Diameter', id: 'diameter' }}
          initialUnit={params?.diameter ?? {}}
          options={[
            { label: 'inches', value: 'inches' },
            { label: 'meters', value: 'meters' },
            { label: 'feet', value: 'feet' },
            { label: 'kilometers', value: 'kilometers' },
          ]}
        />
        <PipeUnitParameter
          title={{ label: 'Absolute Roughness', id: 'roughness' }}
          initialUnit={params?.roughness ?? {}}
          options={[
            { label: 'inches', value: 'inches' },
            { label: 'meters', value: 'meters' },
            { label: 'millimeters', value: 'millimeters' },
            { label: 'feet', value: 'feet' },
          ]}
        />
        <PipeUnitParameter
          title={{ label: 'Inlet Pressure', id: 'inletPressure' }}
          initialUnit={params?.inletPressure ?? {}}
          options={[
            { label: 'psi', value: 'psi' },
            { label: 'bar', value: 'bar' },
            { label: 'atm', value: 'atm' },
          ]}
        />
        <PipeUnitParameter
          title={{ label: 'Inlet Temperature', id: 'inletTemperature' }}
          initialUnit={params?.inletTemperature ?? {}}
          options={[
            { label: 'kelvin', value: 'kelvin' },
            { label: 'celsius', value: 'celsius' },
            { label: 'fahrenheit', value: 'fahrenheit' },
          ]}
        />
      </form>
    </section>
  );
}

export default PipeParameters;
