import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  ValueHTMLTypes,
  TriggerValueBool,
  TriggerValueDouble,
  TriggerValueString,
  TriggerValueUnit,
  TriggerValueType,
} from '../../../types/signalTypes';
import styles from '../../../styles/common/connections/signalSettings.module.scss';

function PossibleValues({
  options,
  initialVal,
  setControlPropValue,
}: {
  options:
    | {
        type: ValueHTMLTypes;
        valueType: TriggerValueType;
        choices: { [key: string]: any }[];
      }
    | undefined;
  initialVal:
    | TriggerValueBool
    | TriggerValueDouble
    | TriggerValueString
    | TriggerValueUnit
    | undefined;
  setControlPropValue: React.Dispatch<
    React.SetStateAction<
      | TriggerValueBool
      | TriggerValueDouble
      | TriggerValueString
      | TriggerValueUnit
      | undefined
    >
  >;
}) {
  const [unit, setUnit] = useState<string>(
    // eslint-disable-next-line no-underscore-dangle
    options?.choices[0]?.value ?? ''
  );

  useEffect(() => {
    if (options?.choices) {
      setUnit(options.choices[0].value);
    }
  }, [options?.choices]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    switch (options?.valueType) {
      case TriggerValueType.BOOL: {
        const val: TriggerValueBool = {
          bool: { _0: e.currentTarget.value === 'true' },
        };
        setControlPropValue(val);
        break;
      }
      case TriggerValueType.STRING: {
        const val: TriggerValueString = {
          string: { _0: e.currentTarget.value },
        };
        setControlPropValue(val);
        break;
      }
      case TriggerValueType.DOUBLE: {
        const val: TriggerValueDouble = {
          double: {
            _0: parseFloat(e.currentTarget.value),
          },
        };
        setControlPropValue(val);
        break;
      }
      case TriggerValueType.UNIT: {
        const val: TriggerValueUnit = {
          value: parseFloat(e.currentTarget.value),
          unit,
        };
        setControlPropValue(val);
        break;
      }
      default:
        break;
    }
    return e.currentTarget.value;
  };

  switch (options?.type) {
    case ValueHTMLTypes.SELECT:
      return (
        <select
          name=""
          id=""
          onChange={handleOnChange}
          // eslint-disable-next-line no-underscore-dangle
          value={String((initialVal as TriggerValueBool)?.bool?._0)}
        >
          {options?.choices?.map((option) => (
            <option value={option.value ?? ''} key={v4()}>
              {option.label ?? ''}
            </option>
          ))}
        </select>
      );
    case ValueHTMLTypes.INPUT:
      return (
        <div className={styles.inputContainer}>
          <input
            type="number"
            onChange={handleOnChange}
            // eslint-disable-next-line no-underscore-dangle
            value={(initialVal as TriggerValueUnit)?.value ?? ''}
          />
          <select
            name=""
            id=""
            onChange={(e) => {
              setUnit(e.currentTarget.value);
            }}
            // eslint-disable-next-line no-underscore-dangle
            value={String((initialVal as TriggerValueUnit)?.unit)}
          >
            {options?.choices?.map((option) => (
              <option value={option.value ?? ''} key={v4()}>
                {option.label ?? ''}
              </option>
            ))}
          </select>
        </div>
      );
    default:
      return null;
  }
}

export default PossibleValues;
