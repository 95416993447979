import {
  ValueHTMLTypes,
  SignalValueType,
  TriggerValueType,
} from '../../types/signalTypes';

export const fermTankSetProperties = [
  {
    setProperty: 'isFinished',
    label: 'Batch Complete',
    type: SignalValueType.BOOLEAN,
    options: {
      valueType: TriggerValueType.BOOL,
      type: ValueHTMLTypes.SELECT,
      choices: [
        {
          value: 'true',
          label: 'True',
        },
        {
          value: 'false',
          label: 'False',
        },
      ],
    },
  },
  {
    setProperty: 'isInDowntime',
    label: 'In Downtime',
    type: SignalValueType.BOOLEAN,
    options: {
      valueType: TriggerValueType.BOOL,
      type: ValueHTMLTypes.SELECT,
      choices: [
        {
          value: 'true',
          label: 'True',
        },
        {
          value: 'false',
          label: 'False',
        },
      ],
    },
  },
];

export const fermTankTriggerValues = [
  {
    triggerProperty: 'isFinished',
    label: 'Batch Complete',
    type: SignalValueType.BOOLEAN,
    options: {
      valueType: TriggerValueType.BOOL,
      type: ValueHTMLTypes.SELECT,
      choices: [
        {
          value: 'true',
          label: 'True',
        },
        {
          value: 'false',
          label: 'False',
        },
      ],
    },
  },
  {
    triggerProperty: 'isInDowntime',
    label: 'In Downtime',
    type: SignalValueType.BOOLEAN,
    options: {
      valueType: TriggerValueType.BOOL,
      type: ValueHTMLTypes.SELECT,
      choices: [
        {
          value: 'true',
          label: 'True',
        },
        {
          value: 'false',
          label: 'False',
        },
      ],
    },
  },
];
