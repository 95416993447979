import React, { useState } from 'react';
import { v4 } from 'uuid';
import styles from '../../styles/common/toggleSwitch.module.scss';

export enum SwitchSize {
  LARGE = 'large',
  SMALL = 'small',
}

function determineSwitchDimensions(size: SwitchSize): {
  width: number;
  height: number;
} {
  switch (size) {
    case SwitchSize.LARGE:
      return { width: 60, height: 34 };
    case SwitchSize.SMALL:
      return { width: 30, height: 17 };
    default:
      return { width: 60, height: 34 };
  }
}

function sliderClassnames(size: SwitchSize, rounded: boolean): string {
  switch (size) {
    case SwitchSize.LARGE:
      return rounded
        ? `${styles.bigSlider} ${styles.rounded} ${styles.slider}`
        : `${styles.bigSlider}`;
    case SwitchSize.SMALL:
      return rounded
        ? `${styles.smallSlider} ${styles.rounded} ${styles.slider}`
        : `${styles.smallSlider}`;
    default:
      return rounded
        ? `${styles.bigSlider} ${styles.rounded} ${styles.slider}`
        : `${styles.bigSlider}`;
  }
}

function ToggleSwitch({
  rounded = false,
  size,
  isToggled,
  onToggle,
}: {
  rounded: boolean;
  size: SwitchSize;
  isToggled: boolean;
  onToggle: () => void;
}) {
  const id = v4();
  const [switchDimensions] = useState<{
    width: number;
    height: number;
  }>(determineSwitchDimensions(size));

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      htmlFor={id}
      className={styles.switch}
      style={{
        width: `${switchDimensions.width}px`,
        height: `${switchDimensions.height}px`,
      }}
    >
      <input
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
        id={id}
        name={id}
      />
      <span className={sliderClassnames(size, rounded)} />
    </label>
  );
}

export default ToggleSwitch;
