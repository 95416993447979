import React, { useEffect, useReducer, useState, useMemo } from 'react';
import App from '../App';
import WebsocketContext, {
  initialTimeSeries,
} from '../context/app/websocket-context';
import WebSocketClient from '../api/websocket';
import timeSeriesReducer from '../context/editor/dashboard/timeSeriesReducer';

function WebSocketWrapper() {
  const [socket, setSocket] = useState(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_, dispatch] = useReducer(timeSeriesReducer);

  useEffect(() => {
    const webSocketClient = new WebSocketClient();
    setSocket(webSocketClient);

    return () => {
      webSocketClient.disconnect();
    };
  }, []);

  return (
    <WebsocketContext.Provider
      value={useMemo(
        () => ({
          socketClient: socket,
          timeSeries: initialTimeSeries,
          timeSeriesReducer: dispatch,
        }),
        [socket, dispatch]
      )}
    >
      <App />
    </WebsocketContext.Provider>
  );
}

export default WebSocketWrapper;
