import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import styles from '../../../styles/common/dashboard/graphSelector.module.scss';

interface GraphSelectorProps {
  main: {
    label: string,
  };
  secondaries: {
    label: string,
  }[];
}

// Component used in selecting multiple datasets to compare on the same graph.
function GraphSelector({ main, secondaries }: GraphSelectorProps) {
  return (
    <section className={styles.selectorBar}>
      <div className={styles.comparisonContainer}>
        <div className={styles.mainComparator}>
          <p className={styles.label}>{main.label}</p>
          <FontAwesomeIcon icon={faCaretDown} color="#6c6c6c" />
        </div>
        <p className={styles.vs}>vs.</p>
        {/* TODO: Add the functionality for multiple selected data points. */}
        <div className={styles.secondaryContainer}>
          {secondaries.map((secondary) => (
            <div className={styles.secondaryComparator} key={v4()}>
              <p className={styles.label}>{secondary.label}</p>
              <FontAwesomeIcon icon={faCaretDown} color="#6c6c6c" />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.gptContainer}>
        <p className={styles.label}>Ask me about this...</p>
      </div>
    </section>
  );
}

export default GraphSelector;
