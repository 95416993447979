import React, { useState } from 'react';
import { v4 } from 'uuid';
import styles from '../../../styles/editor/canvas/editableNode.module.scss';

interface UnitEnumOption {
  value: string;
  label: string;
}

function BooleanParameter({
  title,
  initialUnit,
  options,
  style = undefined,
}: {
  title: { label: string, id: string },
  initialUnit: string,
  options: UnitEnumOption[],
  style?: React.CSSProperties,
}) {
  const [unitEnum, setUnitEnum] = useState(initialUnit);

  return (
    <div className={styles.parameter}>
      <p className={styles.paramText}>{title.label}</p>
      <div className={styles.inputWrapper}>
        <fieldset id={title.id}>
          <select
            name="units"
            id="boolVal"
            className={styles.select}
            value={unitEnum}
            style={style}
            onChange={(e) => {
              setUnitEnum(e.currentTarget.value);
            }}
          >
            {options.map((option) => (
              <option key={v4()} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </fieldset>
      </div>
    </div>
  );
}

BooleanParameter.defaultProps = {
  style: undefined,
};

export default BooleanParameter;
