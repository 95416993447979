import React, { useCallback, useContext, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import EditorState from './context/editor/EditorState';
import Editor from './views/editor';

import 'reactflow/dist/style.css';
import './styles/colors.scss';

import websocketContext from './context/app/websocket-context';
import FermentationTankDashboard from './components/dashboard/nodeDash/FermentationTankDashboard';
import ValveDashboard from './components/dashboard/nodeDash/ValveDashboard';
import PipeDashboard from './components/dashboard/nodeDash/PipeDashboard';
import useSimulationStore, {
  SimulationState,
} from './context/editor/store/simulationStore';
import {
  WebSocketServerResponse,
  WebSocketServerResponseType,
} from './api/types/websocketMessage';
import {
  handleSimulationData,
  handleSimulationStatus,
} from './utils/websocketHandlers';
import Demo from './views/demo';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <EditorState>
        <Editor />
      </EditorState>
    ),
  },
  {
    path: '/dashboard/equipment/fermentationTank/:nodeId?',
    element: <FermentationTankDashboard />,
  },
  {
    path: '/dashboard/equipment/valve/:nodeId?',
    element: <ValveDashboard />,
  },
  {
    path: '/dashboard/equipment/pipe/:nodeId?',
    element: <PipeDashboard />,
  },
  {
    path: '/demo',
    element: <Demo />,
  },
]);

function App() {
  const { socketClient } = useContext(websocketContext);
  const { setSimulating, addStatus } = useSimulationStore(
    (state: SimulationState) => ({
      setSimulating: state.setSimulating,
      addStatus: state.addStatus,
    })
  );

  const dispatchMessage = useCallback((event: any) => {
    // Remove the "isSimulating state when receiving data"
    const data = JSON.parse(event.data) as
      | WebSocketServerResponse<any>
      | undefined;
    if (!data) return;
    switch (data.type) {
      case WebSocketServerResponseType.simData:
        handleSimulationData(data, addStatus);
        break;
      case WebSocketServerResponseType.simStatus:
        handleSimulationStatus(data, addStatus, setSimulating);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    socketClient?.socket.addEventListener('message', dispatchMessage);
  }, [socketClient?.socket, dispatchMessage]);

  return (
    <div className="App">
      <section className="w-screen h-screen">
        <RouterProvider router={router} />
      </section>
    </div>
  );
}

export default App;
