import { Unit } from '../../../api/types/nodes';

export interface DataValue {
  value: number;
  index: number | null;
}

export function calculateMax(data: Unit[], abs: boolean = false): DataValue {
  let values: number[] = [];
  if (abs) {
    values = data.map((datem) => Math.abs(datem.value));
  } else {
    values = data.map((datem) => datem.value);
  }
  return values.reduce(
    (acc, val, index) => {
      if (val > acc.value) {
        return { value: Math.round(val * 100) / 100, index };
      }
      return acc;
    },
    { value: -Infinity, index: -1 }
  );
}

export function calculateMin(data: Unit[], abs: boolean = false): DataValue {
  let values: number[] = [];
  if (abs) {
    values = data.map((datem) => Math.abs(datem.value));
  } else {
    values = data.map((datem) => datem.value);
  }
  return values.reduce(
    (acc, val, index) => {
      if (val < acc.value) {
        return { value: Math.round(val * 100) / 100, index };
      }
      return acc;
    },
    { value: Infinity, index: -1 }
  );
}

export function calculateAverage(data: Unit[]): number {
  const values = data.map((datem) => datem.value);
  if (values.length === 0) return 0;
  return Math.round(values.reduce((a, b) => a + b) / values.length);
}

export function calculateAvgROC(data: Unit[]): number {
  const values = data.map((datem) => datem.value);
  if (values.length < 2) return 0;
  const firstEle = values.shift();
  const lastEle = values.pop();
  return Math.round(((lastEle ?? 0) - (firstEle ?? 0)) / values.length);
}

export function calculateMaxROC(data: Unit[], abs: boolean = false): DataValue {
  let greatestChange = { value: -Infinity, index: -1 };
  let values: number[] = [];
  if (abs) {
    values = data.map((datem) => Math.abs(datem.value));
  } else {
    values = data.map((datem) => datem.value);
  }
  for (let i = 1; i < values.length; i += 1) {
    const difference = Math.abs(values[i] - values[i - 1]);
    if (difference > greatestChange.value) {
      greatestChange = { value: difference, index: i };
    }
  }

  return { ...greatestChange, value: Math.round(greatestChange.value) };
}

export function calculateMinROC(data: Unit[]): DataValue {
  let smallestChange = { value: Infinity, index: -1 };
  const values = data.map((datem) => datem.value);
  for (let i = 1; i < values.length; i += 1) {
    const difference = Math.abs(values[i] - values[i - 1]);
    if (difference < smallestChange.value) {
      smallestChange = { value: difference, index: i };
    }
  }

  return { ...smallestChange, value: Math.round(smallestChange.value) };
}

export function calculateTotalChange(data: Unit[]): number {
  const values = data.map((datem) => datem.value);
  if (values.length < 2) return 0;
  const firstEle = values.shift();
  const lastEle = values.pop();

  return (lastEle ?? 0) - (firstEle ?? 0);
}
