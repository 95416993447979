import React from 'react';
import { ReactFlowProvider } from 'reactflow';
import EquipmentMenu from '../components/editor/menu/equipmentMenu';
import Canvas from '../components/editor/canvas/canvas';
import ButtonControlBar from '../components/editor/buttonController/buttonController';
import useSignalStore, {
  SignalState,
} from '../context/editor/store/signalStore';
import SignalSettings from '../components/common/connections/SignalSettings';
import usePipeStore, { PipeState } from '../context/editor/store/pipeStore';
import PipeSettings from '../components/common/connections/PipeSettings';

function Demo() {
  const { activeSignals } = useSignalStore((state: SignalState) => ({
    activeSignals: state.activeSignalSettings,
  }));
  const { activePipes } = usePipeStore((state: PipeState) => ({
    activePipes: state.activePipeSettings,
  }));

  return (
    <main style={{ position: 'relative' }}>
      <ReactFlowProvider>
        <Canvas isDemo />
        {Object.keys(activePipes).map((pipe) => {
          const pipeDictionary = activePipes[pipe];
          return (
            <PipeSettings
              id={pipe}
              key={pipe}
              params={pipeDictionary.parameters}
              clientX={pipeDictionary.clientX}
              clientY={pipeDictionary.clientY}
              sourceID={pipeDictionary.sourceID}
              targetID={pipeDictionary.targetID}
            />
          );
        })}
      </ReactFlowProvider>
      <EquipmentMenu />
      <ButtonControlBar />

      {Object.keys(activeSignals).map((signal) => {
        const signalDictionary = activeSignals[signal];
        return (
          <SignalSettings
            id={signal}
            key={signal}
            clientX={signalDictionary.clientX}
            clientY={signalDictionary.clientY}
            sourceID={signalDictionary.sourceID}
            targetID={signalDictionary.targetID}
          />
        );
      })}
    </main>
  );
}

export default Demo;
