export enum EquipmentMenuActionTypes {
  TOGGLE = 'TOGGLE',
}

export interface EquipmentMenuState {
  on: boolean;
}

export interface EquipmentReducerAction {
  type: EquipmentMenuActionTypes;
}
