const demoData = {
  nodes: [
    {
      width: 300,
      height: 68,
      id: '1712095839379',
      type: 'fermentationTank',
      position: {
        x: 609.3899216202897,
        y: -367.58983816564233,
      },
      data: {
        label: '25,000 Liter Tank',
        type: 'fermentationTank',
        data: {},
        handles: {
          sourceHandleRight: 'tank_634280f3-e3f7-4c31-be94-5ff61148cf8b',
          sourceHandleBottom: 'tank_656cfd42-9ac1-4e1a-a1d9-105886a4cbdf',
          targetHandleTop: 'tank_2b54757a-f23d-4208-acaf-85a1a61009f1',
          targetHandleLeft: 'tank_b727fc0b-d8fe-411a-bb44-0d38a3b77301',
          sourceSignalHandleRight:
            'tank_signal_a8951ca0-9665-42b1-b495-64e1566f12e2',
          sourceSignalHandleBottom:
            'tank_signal_a16ff569-bdce-467f-bf4e-cb2bec5327ff',
          targetSignalHandleTop:
            'tank_signal_8e59833e-e38d-4b8b-9d59-c13109e4d5ce',
          targetSignalHandleLeft:
            'tank_signal_b5e38791-d947-456f-b65b-f698ae47fa43',
        },
        parameters: {
          tankType: 'batch',
          resetBetweenBatches: true,
          batchTime: {
            hours: {
              _0: 12,
            },
          },
          downTime: {
            hours: {
              _0: 1,
            },
          },
          tankSize: {
            value: 50000,
            unit: 'liters',
          },
          currentVolume: {
            value: 18750,
            unit: 'liters',
          },
          kinetics: {
            all: {
              _0: {
                maximumSpecificGrowthRate: {
                  value: 0.24,
                  unit: 'inverseHours',
                },
                citricAcidGrowthProductionCoefficient: {
                  value: 0.064,
                  unit: 'unitless',
                },
                citricAcidBiomassProductionCoefficient: {
                  value: 0.019,
                  unit: 'inverseHours',
                },
                halfVmaxProduction: {
                  value: 1.45,
                  unit: 'unitless',
                },
                halfVmaxGrowth: {
                  value: 73.54,
                  unit: 'unitless',
                },
                reciprocalYieldCoeffBiomass: {
                  value: 2.857,
                  unit: 'unitless',
                },
                reciprocalYieldCoeffProduct: {
                  value: 0.97,
                  unit: 'unitless',
                },
              },
            },
          },
          currentLoading: [
            {
              Z: 0.000001,
              criticalPressure: {
                value: 218.12396,
                unit: 'bar',
              },
              A: 6.20963,
              fluidRole: 'substrate',
              massFrac: 0.2,
              dof: 6,
              density: {
                value: 1.5074366,
                unit: 'gramsPerCubicCentimeter',
              },
              concentration: {
                unit: 'gramsPerLiter',
                value: 1000,
              },
              B: 2354.731,
              viscosity: {
                unit: 'centipoise',
                value: 48.63,
              },
              volumeFrac: 0.13911821,
              moleFrac: 0.012986648,
              molecularWeight: 342.30008,
              C: 7.559,
              name: 'Sucrose',
              id: 'c6053ed3-215c-4d79-8b7d-8777e44e1b90',
            },
            {
              Z: 0.000001,
              massFrac: 1,
              molecularWeight: 210.14,
              A: 6.20963,
              density: {
                value: 1.542,
                unit: 'gramsPerCubicCentimeter',
              },
              concentration: {
                unit: 'gramsPerLiter',
                value: 6.48358,
              },
              name: 'Citric Acid',
              fluidRole: 'product',
              C: 7.559,
              viscosity: {
                unit: 'centipoise',
                value: 6.5,
              },
              dof: 6,
              B: 2354.731,
              criticalPressure: {
                value: 3200.1,
                unit: 'psi',
              },
              moleFrac: 1,
              volumeFrac: 1,
              id: 'f2950ee7-efd5-4ac6-a402-abe7e2cd4987',
            },
            {
              moleFrac: 1,
              volumeFrac: 1,
              C: 7.559,
              fluidRole: 'biomass',
              massFrac: 1,
              name: 'A. niger',
              molecularWeight: 23.8146,
              A: 6.20963,
              concentration: {
                unit: 'gramsPerLiter',
                value: 36.702106,
              },
              criticalPressure: {
                value: 3200.1,
                unit: 'psi',
              },
              Z: 0.000001,
              B: 2354.731,
              density: {
                value: 1041.667,
                unit: 'kilogramPerCubicMeter',
              },
              viscosity: {
                value: 0.89,
                unit: 'centipoise',
              },
              dof: 6,
              id: 'f4c30693-02b6-4378-9e71-8a007435edb4',
            },
          ],
          mixture: {
            fluids: [
              {
                Z: 0.000001,
                criticalPressure: {
                  value: 218.12396,
                  unit: 'bar',
                },
                A: 6.20963,
                fluidRole: 'substrate',
                massFrac: 0.2,
                dof: 6,
                density: {
                  value: 1.5074366,
                  unit: 'gramsPerCubicCentimeter',
                },
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 1000,
                },
                B: 2354.731,
                viscosity: {
                  unit: 'centipoise',
                  value: 48.63,
                },
                volumeFrac: 0.13911821,
                moleFrac: 0.012986648,
                molecularWeight: 342.30008,
                C: 7.559,
                name: 'Sucrose',
                id: 'c6053ed3-215c-4d79-8b7d-8777e44e1b90',
              },
              {
                Z: 0.000001,
                massFrac: 1,
                molecularWeight: 210.14,
                A: 6.20963,
                density: {
                  value: 1.542,
                  unit: 'gramsPerCubicCentimeter',
                },
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 6.48358,
                },
                name: 'Citric Acid',
                fluidRole: 'product',
                C: 7.559,
                viscosity: {
                  unit: 'centipoise',
                  value: 6.5,
                },
                dof: 6,
                B: 2354.731,
                criticalPressure: {
                  value: 3200.1,
                  unit: 'psi',
                },
                moleFrac: 1,
                volumeFrac: 1,
                id: 'f2950ee7-efd5-4ac6-a402-abe7e2cd4987',
              },
              {
                moleFrac: 1,
                volumeFrac: 1,
                C: 7.559,
                fluidRole: 'biomass',
                massFrac: 1,
                name: 'A. niger',
                molecularWeight: 23.8146,
                A: 6.20963,
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 36.702106,
                },
                criticalPressure: {
                  value: 3200.1,
                  unit: 'psi',
                },
                Z: 0.000001,
                B: 2354.731,
                density: {
                  value: 1041.667,
                  unit: 'kilogramPerCubicMeter',
                },
                viscosity: {
                  value: 0.89,
                  unit: 'centipoise',
                },
                dof: 6,
                id: 'f4c30693-02b6-4378-9e71-8a007435edb4',
              },
            ],
          },
          inletPressure: {
            value: 4,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
      },
      positionAbsolute: {
        x: 609.3899216202897,
        y: -367.58983816564233,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 300,
      height: 68,
      id: '1712419460985',
      type: 'valve',
      position: {
        x: 1029.4589030427624,
        y: -367.31669641585336,
      },
      data: {
        label: 'Valve',
        type: 'valve',
        data: {},
        handles: {
          sourceHandleRight: 'valve_ef253b88-c7e5-40df-9217-6bbdc829a568',
          targetHandleLeft: 'valve_5dbc0e98-0b84-4da6-a470-8ca26133d909',
          sourceSignalHandleRight:
            'valve_signal_1c8de463-4879-44bb-986f-e3c083715d43',
          targetSignalHandleLeft:
            'valve_signal_7ccb2289-a941-4022-b692-b338cf006b74',
          sourceSignalHandleBottom:
            'valve_signal_ad4f8b29-c9bd-4fae-8062-6f50754d4bd2',
          targetSignalHandleTop:
            'valve_signal_e88b26d2-b30b-4310-ab51-03f076dffd39',
        },
        parameters: {
          maxCv: {
            value: 100,
            unit: 'unitless',
          },
          initialSetPointOpenness: {
            value: 80,
            unit: 'percentage',
          },
          openness: {
            value: 80,
            unit: 'percentage',
          },
          valveType: 'Linear',
        },
      },
      positionAbsolute: {
        x: 1029.4589030427624,
        y: -367.31669641585336,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 300,
      height: 68,
      id: '1712524906103',
      type: 'valve',
      position: {
        x: 1444.3131151145383,
        y: -484.68264770345684,
      },
      data: {
        label: 'Sucrose Valve',
        type: 'valve',
        data: {},
        handles: {
          sourceHandleRight: 'valve_49ef37a0-2d39-4115-b30a-3e8ed4f2e608',
          targetHandleLeft: 'valve_de74825a-38a3-40f7-b421-404369327a35',
          sourceSignalHandleRight:
            'valve_signal_df3ef436-17d1-4831-8e37-26081ddead70',
          targetSignalHandleLeft:
            'valve_signal_d8c5b5ed-eb91-40c3-85b7-1111f26f337d',
          sourceSignalHandleBottom:
            'valve_signal_88cd75a2-ca6d-41ff-9144-66f9626a9cee',
          targetSignalHandleTop:
            'valve_signal_b2bf2b9f-4ab7-45ac-91e6-520492e952f0',
        },
        parameters: {
          maxCv: {
            value: 40,
            unit: 'unitless',
          },
          initialSetPointOpenness: {
            value: 0,
            unit: 'percentage',
          },
          openness: {
            value: 0,
            unit: 'percentage',
          },
          valveType: 'Linear',
        },
      },
      positionAbsolute: {
        x: 1444.3131151145383,
        y: -484.68264770345684,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 300,
      height: 68,
      id: '1712524919198',
      type: 'source',
      position: {
        x: 1442.9911268235705,
        y: -601.8127087813493,
      },
      data: {
        label: 'Sucrose Source',
        type: 'source',
        data: {},
        handles: {},
        sourceHandleRight: 'valve_c1ec9873-fa34-4f51-b9ca-8152c339d2b6',
        sourceHandleLeft: 'valve_7fc24ab5-0e43-4fd5-9bdd-09363bfbc901',
        sourceHandleBottom: 'valve_36f033cd-4954-4b02-8a22-ac6f88a8e26d',
        sourceHandleTop: 'valve_072f1b4e-f254-4dd4-9f1c-269931c171a0',
        parameters: {
          flowRate: {
            value: 4,
            unit: 'galPerMinute',
          },
          inletPressure: {
            value: 4,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
      },
      positionAbsolute: {
        x: 1442.9911268235705,
        y: -601.8127087813493,
      },
      selected: false,
      dragging: false,
    },
    {
      width: 300,
      height: 68,
      id: '1712715227568',
      type: 'fermentationTank',
      position: {
        x: 1446.3289929761766,
        y: -367.0176503737216,
      },
      data: {
        label: '250,000 Liter Tank',
        type: 'fermentationTank',
        data: {},
        handles: {
          sourceHandleRight: 'tank_af7c3a15-a82b-4061-ac8f-7b4a28e471cc',
          sourceHandleBottom: 'tank_386595b0-10b2-4b60-85b8-da543f97cca5',
          targetHandleTop: 'tank_69226f9a-a31e-4a3d-a50b-fd789a930e2b',
          targetHandleLeft: 'tank_7f16f224-8a08-4836-8c8e-e1a30615ac65',
          sourceSignalHandleRight:
            'tank_signal_7227c1a5-5d11-4c57-969f-8d7c1b3cfb79',
          sourceSignalHandleBottom:
            'tank_signal_fa2fadef-46b6-4de1-8c04-37870abc29a5',
          targetSignalHandleTop:
            'tank_signal_8ace3254-3cd0-4b25-9af9-ad690c14b743',
          targetSignalHandleLeft:
            'tank_signal_aca6b5fe-4484-4b5c-a176-3e849ab985ce',
        },
        parameters: {
          tankType: 'batch',
          resetBetweenBatches: false,
          downTime: {
            hours: {
              _0: 5,
            },
          },
          batchTime: {
            days: {
              _0: 8,
            },
          },
          tankSize: {
            value: 250000,
            unit: 'liters',
          },
          currentVolume: {
            value: 0,
            unit: 'liters',
          },
          inletPressure: {
            value: 2,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
          currentLoading: [
            {
              Z: 0.000001,
              criticalPressure: {
                value: 218.12396,
                unit: 'bar',
              },
              A: 6.20963,
              fluidRole: 'substrate',
              massFrac: 0.2,
              dof: 6,
              density: {
                value: 1.5074366,
                unit: 'gramsPerCubicCentimeter',
              },
              concentration: {
                unit: 'gramsPerLiter',
                value: 0,
              },
              B: 2354.731,
              viscosity: {
                unit: 'centipoise',
                value: 48.63,
              },
              volumeFrac: 0.13911821,
              moleFrac: 0.012986648,
              molecularWeight: 342.30008,
              C: 7.559,
              name: 'Sucrose',
              id: 'a45b7770-8727-4ee0-9414-75a786d16a5a',
            },
            {
              Z: 0.000001,
              massFrac: 1,
              molecularWeight: 210.14,
              A: 6.20963,
              density: {
                value: 1.542,
                unit: 'gramsPerCubicCentimeter',
              },
              concentration: {
                unit: 'gramsPerLiter',
                value: 0,
              },
              name: 'Citric Acid',
              fluidRole: 'product',
              C: 7.559,
              viscosity: {
                unit: 'centipoise',
                value: 6.5,
              },
              dof: 6,
              B: 2354.731,
              criticalPressure: {
                value: 3200.1,
                unit: 'psi',
              },
              moleFrac: 1,
              volumeFrac: 1,
              id: '60c4b1c3-d0e6-4947-9cf2-9fc85f7aa0c8',
            },
            {
              moleFrac: 1,
              volumeFrac: 1,
              C: 7.559,
              fluidRole: 'biomass',
              massFrac: 1,
              name: 'A. niger',
              molecularWeight: 23.8146,
              A: 6.20963,
              concentration: {
                unit: 'gramsPerLiter',
                value: 0,
              },
              criticalPressure: {
                value: 3200.1,
                unit: 'psi',
              },
              Z: 0.000001,
              B: 2354.731,
              density: {
                value: 1041.667,
                unit: 'kilogramPerCubicMeter',
              },
              viscosity: {
                value: 0.89,
                unit: 'centipoise',
              },
              dof: 6,
              id: '94e95383-27e1-44a0-8d46-21479b43f599',
            },
          ],
          mixture: {
            fluids: [
              {
                Z: 0.000001,
                criticalPressure: {
                  value: 218.12396,
                  unit: 'bar',
                },
                A: 6.20963,
                fluidRole: 'substrate',
                massFrac: 0.2,
                dof: 6,
                density: {
                  value: 1.5074366,
                  unit: 'gramsPerCubicCentimeter',
                },
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 0,
                },
                B: 2354.731,
                viscosity: {
                  unit: 'centipoise',
                  value: 48.63,
                },
                volumeFrac: 0.13911821,
                moleFrac: 0.012986648,
                molecularWeight: 342.30008,
                C: 7.559,
                name: 'Sucrose',
                id: 'a45b7770-8727-4ee0-9414-75a786d16a5a',
              },
              {
                Z: 0.000001,
                massFrac: 1,
                molecularWeight: 210.14,
                A: 6.20963,
                density: {
                  value: 1.542,
                  unit: 'gramsPerCubicCentimeter',
                },
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 0,
                },
                name: 'Citric Acid',
                fluidRole: 'product',
                C: 7.559,
                viscosity: {
                  unit: 'centipoise',
                  value: 6.5,
                },
                dof: 6,
                B: 2354.731,
                criticalPressure: {
                  value: 3200.1,
                  unit: 'psi',
                },
                moleFrac: 1,
                volumeFrac: 1,
                id: '60c4b1c3-d0e6-4947-9cf2-9fc85f7aa0c8',
              },
              {
                moleFrac: 1,
                volumeFrac: 1,
                C: 7.559,
                fluidRole: 'biomass',
                massFrac: 1,
                name: 'A. niger',
                molecularWeight: 23.8146,
                A: 6.20963,
                concentration: {
                  unit: 'gramsPerLiter',
                  value: 0,
                },
                criticalPressure: {
                  value: 3200.1,
                  unit: 'psi',
                },
                Z: 0.000001,
                B: 2354.731,
                density: {
                  value: 1041.667,
                  unit: 'kilogramPerCubicMeter',
                },
                viscosity: {
                  value: 0.89,
                  unit: 'centipoise',
                },
                dof: 6,
                id: '94e95383-27e1-44a0-8d46-21479b43f599',
              },
            ],
          },
          kinetics: {
            all: {
              _0: {
                maximumSpecificGrowthRate: {
                  value: 0.066,
                  unit: 'inverseDays',
                },
                halfVmaxProduction: {
                  value: 2.74,
                  unit: 'unitless',
                },
                halfVmaxGrowth: {
                  value: 76.6,
                  unit: 'unitless',
                },
                reciprocalYieldCoeffBiomass: {
                  value: 5.529,
                  unit: 'unitless',
                },
                reciprocalYieldCoeffProduct: {
                  value: 0.9376,
                  unit: 'unitless',
                },
                citricAcidGrowthProductionCoefficient: {
                  value: 16.6,
                  unit: 'unitless',
                },
                citricAcidBiomassProductionCoefficient: {
                  value: 0.0195,
                  unit: 'inverseDays',
                },
              },
            },
          },
        },
      },
      positionAbsolute: {
        x: 1446.3289929761766,
        y: -367.0176503737216,
      },
      selected: false,
      dragging: false,
    },
  ],
  edges: [
    {
      source: '1712095839379',
      sourceHandle: 'tank_634280f3-e3f7-4c31-be94-5ff61148cf8b',
      target: '1712419460985',
      targetHandle: 'valve_5dbc0e98-0b84-4da6-a470-8ca26133d909',
      id: 'reactflow__edge-1712095839379tank_634280f3-e3f7-4c31-be94-5ff61148cf8b-1712419460985valve_5dbc0e98-0b84-4da6-a470-8ca26133d909',
      type: 'pipe',
      data: {
        label: 'Pipe',
        parameters: {
          inletPressure: {
            value: 4,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
        type: 'pipe',
      },
      selected: false,
    },
    {
      source: '1712524919198',
      sourceHandle: 'valve_36f033cd-4954-4b02-8a22-ac6f88a8e26d',
      target: '1712524906103',
      targetHandle: 'valve_de74825a-38a3-40f7-b421-404369327a35',
      id: 'reactflow__edge-1712524919198valve_36f033cd-4954-4b02-8a22-ac6f88a8e26d-1712524906103valve_de74825a-38a3-40f7-b421-404369327a35',
      type: 'pipe',
      data: {
        label: 'Pipe',
        parameters: {
          inletPressure: {
            value: 4,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
        type: 'pipe',
      },
      selected: false,
    },
    {
      source: '1712524906103',
      sourceHandle: 'valve_49ef37a0-2d39-4115-b30a-3e8ed4f2e608',
      target: '1712715227568',
      targetHandle: 'tank_69226f9a-a31e-4a3d-a50b-fd789a930e2b',
      id: 'reactflow__edge-1712524906103valve_49ef37a0-2d39-4115-b30a-3e8ed4f2e608-1712715227568tank_69226f9a-a31e-4a3d-a50b-fd789a930e2b',
      type: 'pipe',
      data: {
        label: 'Pipe',
        parameters: {
          inletPressure: {
            value: 2,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
        type: 'pipe',
      },
      selected: false,
    },
    {
      source: '1712715227568',
      sourceHandle: 'tank_signal_7227c1a5-5d11-4c57-969f-8d7c1b3cfb79',
      target: '1712524906103',
      targetHandle: 'valve_de74825a-38a3-40f7-b421-404369327a35',
      id: 'reactflow__edge-1712715227568tank_signal_7227c1a5-5d11-4c57-969f-8d7c1b3cfb79-1712524906103valve_de74825a-38a3-40f7-b421-404369327a35',
      type: 'signal',
      data: {
        label: 'Signal',
        rawControlSignal: {
          rawControlSource: {
            id: '1712715227568',
            type: 'fermentationTank',
            triggerProperty: 'isFinished',
            triggerValue: {
              bool: {
                _0: true,
              },
            },
            comparison: 'equalTo',
          },
          rawControlTarget: {
            id: '1712524906103',
            type: 'valve',
            setProperty: 'setPointOpenness',
            setValue: {
              value: 0,
              unit: 'percentage',
            },
          },
        },
      },
      selected: false,
    },
    {
      source: '1712715227568',
      sourceHandle: 'tank_signal_7227c1a5-5d11-4c57-969f-8d7c1b3cfb79',
      target: '1712524906103',
      targetHandle: 'valve_signal_b2bf2b9f-4ab7-45ac-91e6-520492e952f0',
      id: 'reactflow__edge-1712715227568tank_signal_7227c1a5-5d11-4c57-969f-8d7c1b3cfb79-1712524906103valve_signal_b2bf2b9f-4ab7-45ac-91e6-520492e952f0',
      type: 'signal',
      data: {
        label: 'Signal',
        rawControlSignal: {
          rawControlSource: {
            id: '1712715227568',
            type: 'fermentationTank',
            triggerProperty: 'isFinished',
            triggerValue: {
              bool: {
                _0: false,
              },
            },
            comparison: 'equalTo',
          },
          rawControlTarget: {
            id: '1712524906103',
            type: 'valve',
            setProperty: 'setPointOpenness',
            setValue: {
              value: 80,
              unit: 'percentage',
            },
          },
        },
      },
      selected: false,
    },
    {
      source: '1712715227568',
      sourceHandle: 'tank_signal_fa2fadef-46b6-4de1-8c04-37870abc29a5',
      target: '1712419460985',
      targetHandle: 'valve_signal_e88b26d2-b30b-4310-ab51-03f076dffd39',
      id: 'reactflow__edge-1712715227568tank_signal_fa2fadef-46b6-4de1-8c04-37870abc29a5-1712419460985valve_signal_e88b26d2-b30b-4310-ab51-03f076dffd39',
      type: 'signal',
      data: {
        label: 'Signal',
        rawControlSignal: {
          rawControlSource: {
            id: '1712715227568',
            type: 'fermentationTank',
            triggerProperty: 'isInDowntime',
            triggerValue: {
              bool: {
                _0: true,
              },
            },
            comparison: 'equalTo',
          },
          rawControlTarget: {
            id: '1712419460985',
            type: 'valve',
            setProperty: 'setPointOpenness',
            setValue: {
              value: 80,
              unit: 'percentage',
            },
          },
        },
      },
      selected: false,
    },
    {
      source: '1712715227568',
      sourceHandle: 'tank_signal_fa2fadef-46b6-4de1-8c04-37870abc29a5',
      target: '1712419460985',
      targetHandle: 'valve_signal_7ccb2289-a941-4022-b692-b338cf006b74',
      id: 'reactflow__edge-1712715227568tank_signal_fa2fadef-46b6-4de1-8c04-37870abc29a5-1712419460985valve_signal_7ccb2289-a941-4022-b692-b338cf006b74',
      type: 'signal',
      data: {
        label: 'Signal',
        rawControlSignal: {
          rawControlSource: {
            id: '1712715227568',
            type: 'fermentationTank',
            triggerProperty: 'isFinished',
            triggerValue: {
              bool: {
                _0: false,
              },
            },
            comparison: 'equalTo',
          },
          rawControlTarget: {
            id: '1712419460985',
            type: 'valve',
            setProperty: 'setPointOpenness',
            setValue: {
              value: 0,
              unit: 'percentage',
            },
          },
        },
      },
      selected: false,
    },
    {
      source: '1712419460985',
      sourceHandle: 'valve_ef253b88-c7e5-40df-9217-6bbdc829a568',
      target: '1712715227568',
      targetHandle: 'tank_7f16f224-8a08-4836-8c8e-e1a30615ac65',
      id: 'reactflow__edge-1712419460985valve_ef253b88-c7e5-40df-9217-6bbdc829a568-1712715227568tank_7f16f224-8a08-4836-8c8e-e1a30615ac65',
      type: 'pipe',
      data: {
        label: 'Pipe',
        parameters: {
          inletPressure: {
            value: 2,
            unit: 'bar',
          },
          inletTemperature: {
            value: 298,
            unit: 'kelvin',
          },
        },
        type: 'pipe',
      },
      selected: false,
    },
  ],
  viewport: {
    x: -636.2073347492624,
    y: 925.1156688223554,
    zoom: 1.1514169422726566,
  },
};

export default demoData;
