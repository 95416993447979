import React, { ChangeEventHandler } from 'react';
import { useReactFlow } from 'reactflow';
import styles from '../../../../../styles/editor/canvas/editableNode.module.scss';
import {
  flattenEnumParameters,
  flattenUnitParameters,
} from '../Utils/flattenParameters';
import UnitParameter from '../../../../common/nodes/unitParameter';
import EnumParameter from '../../../../common/nodes/enumParameter';

function ValveParameters({
  id,
  params,
}: {
  id: string;
  params: { [key: string]: any };
}) {
  const { setNodes } = useReactFlow();

  const updateData: ChangeEventHandler<HTMLFormElement> = (e) => {
    const flattenedUnitParameters = flattenUnitParameters(e);
    const flattenedEnumParameters = flattenEnumParameters(e);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              parameters: {
                ...flattenedUnitParameters,
                ...flattenedEnumParameters,
              },
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <section className={styles.middleSection}>
      <form action="" onChange={updateData}>
        <UnitParameter
          title={{ label: 'Maximum Cv', id: 'maxCv' }}
          initialUnit={params?.maxCv ?? {}}
          options={[{ label: 'unitless', value: 'unitless' }]}
          style={{ display: 'none' }}
        />
        <UnitParameter
          title={{ label: 'Orifice Diameter', id: 'orificeDiameter' }}
          initialUnit={params?.orificeDiameter ?? {}}
          options={[
            { label: 'inches', value: 'inches' },
            { label: 'meters', value: 'meters' },
            { label: 'feet', value: 'feet' },
          ]}
        />
        <UnitParameter
          title={{ label: 'Valve Speed', id: 'valveSpeed' }}
          initialUnit={params?.valveSpeed ?? {}}
          options={[{ label: '% / second', value: 'percentPerSecond' }]}
        />
        <UnitParameter
          title={{ label: 'Initial Setpoint', id: 'initialSetPointOpenness' }}
          initialUnit={params?.initialSetPointOpenness ?? {}}
          options={[{ label: '%', value: 'percentage' }]}
        />
        <UnitParameter
          title={{ label: 'Initial Openness', id: 'openness' }}
          initialUnit={params?.openness ?? {}}
          options={[{ label: '%', value: 'percentage' }]}
        />
        <EnumParameter
          title={{ label: 'Valve Type', id: 'valveType' }}
          initialUnit="linear"
          options={[{ label: 'linear', value: 'Linear' }]}
        />
      </form>
    </section>
  );
}

export default ValveParameters;
