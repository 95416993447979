import React from 'react';
import { v4 } from 'uuid';
import styles from '../../../styles/common/dashboard/dataContainer.module.scss';

interface ValuePoint {
  label: string;
  value: number | string;
  unit: string;
}

interface DataContainerProps {
  left: ValuePoint[];
  middle: ValuePoint[];
  right: ValuePoint[];
}

function DataContainer({ left, middle, right }: DataContainerProps) {
  const maxRows = Math.max(left.length, middle.length, right.length);

  return (
    <table className={styles.table}>
      <tbody className={styles.body}>
        {Array.from({ length: maxRows }).map((_, index) => (
          // TODO: Add an active label argument to select the row.
          <tr key={v4()} className={styles.row}>
            <td
              className={
                left[index]
                  ? `${styles.hoverable} ${styles.col}`
                  : `${styles.col}`
              }
            >
              <div className={styles.item}>
                <p className={styles.label}>{left[index]?.label || ''}</p>
                <p className={styles.value}>
                  {left[index]?.value === 0
                    ? `${left[index]?.value} ${left[index]?.unit}`
                    : `${left[index]?.value || ''} ${left[index]?.unit || ''}`}
                </p>
              </div>
            </td>
            <td
              className={
                middle[index]
                  ? `${styles.hoverable} ${styles.col} ${styles.middleCol}`
                  : `${styles.col} ${styles.middleCol}`
              }
            >
              <div className={styles.item}>
                <p className={styles.label}>{middle[index]?.label || ''}</p>
                <p className={styles.value}>
                  {middle[index]?.value === 0
                    ? `${middle[index]?.value} ${middle[index]?.unit}`
                    : `${middle[index]?.value || ''} ${
                        middle[index]?.unit || ''
                      }`}
                </p>
              </div>
            </td>
            <td
              className={
                right[index]
                  ? `${styles.hoverable} ${styles.col}`
                  : `${styles.col}`
              }
            >
              <div className={styles.item}>
                <p className={styles.label}>{right[index]?.label || ''}</p>
                <p className={styles.value}>
                  {right[index]?.value === 0
                    ? `${right[index]?.value} ${right[index]?.unit}`
                    : `${right[index]?.value || ''} ${
                        right[index]?.unit || ''
                      }`}
                </p>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataContainer;
