export interface SimulationStatus {
  status: StatusType;
  message: string | undefined;
  title: string;
  id: string;
}

export enum StatusType {
  warning = 'warning',
  error = 'error',
  success = 'success',
}
