// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand';

export type PipeState = {
  // A dictionary of the pipes and their settings
  pipeDictionary: {
    // Signal Edge ID
    [key: string]: {};
  };

  // A dictionary of the active pipe settings popups
  activePipeSettings: {
    // Signal Edge ID
    [key: string]: {
      parameters: { [key: string]: any };
      clientX: number;
      clientY: number;
      sourceID: string;
      targetID: string;
    };
  };

  setActivePipeSettings: (options: PipeSettingsType) => void;
};

export interface PipeSettingsType {
  params: { [key: string]: any };
  clientX: number;
  clientY: number;
  id: string;
  sourceID: string;
  targetID: string;
}

const usePipeStore = create<PipeState>((set, get) => ({
  activePipeSettings: {},
  pipeDictionary: {},
  setActivePipeSettings(options) {
    const { id, clientX, clientY, sourceID, targetID, params } = options;
    const maybeExists = get().activePipeSettings[id];

    if (maybeExists) {
      const newObj = { ...get().activePipeSettings };
      delete newObj[id];

      set({
        activePipeSettings: newObj,
      });
    } else {
      set({
        activePipeSettings: {
          ...get().activePipeSettings,
          [id]: {
            parameters: params,
            clientX,
            clientY,
            sourceID,
            targetID,
          },
        },
      });
    }
  },
}));

export default usePipeStore;
